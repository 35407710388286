import { useState } from 'react';
import PopupComponent from '../PopupComponent/PopupComponent';
import './RewardsItem.css'
import RewardsItemDetail from '../RewardsItemDetail/RewardsItemDetail';
import RectangleContent from '../PopupContentComponents/RectangleContent';
const RewardsItem = ({ item }) => {
    const [visible, setVisible] = useState(false)

    const closeHandler = () => {
        setVisible(false);
    }

    const rectangularChild = () => {
        return (
            <RectangleContent
                title={item.name}
                closeHandler={(e)=>{closeHandler()}}
            >
                <RewardsItemDetail rewardItem={item}/>
            </RectangleContent>
        );
    }
    return (
        <>
            <div className="rewards-item" onClick={()=>setVisible(!visible)}>
                <img className="rewards-item-img" src={item.image.url} alt={item.image_alt}/>
                <p className='rewards-item-name'>{item.name}</p>
            </div>

            <PopupComponent
                show={visible}
                overlayClassname={'overlay'}
                children={rectangularChild()}
            />
            
        </>
    );
}

export default RewardsItem;