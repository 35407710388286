import './NavBar.css';
import BeetestedLogo from "../BeetestedLogo/BeetestedLogo";
import { Link } from 'react-router-dom';
import MenuItems from '../MenuItems/MenuItems';


const NavBar = () => {

    return(
        <div className='nav-bar'>
            <Link to='/'>
                <BeetestedLogo className='header-img' />
            </Link>
            <MenuItems />
        </div>
    )
};

export default NavBar;