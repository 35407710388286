import React, { useState, useContext, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../services/auth.service';
import './ForgotPassword.css';
import Reaptcha from "reaptcha";
import { DRF_RECAPTCHA_PUBLIC_KEY } from '../../constants';
import ErrorsComponent from '../ErrorsComponent/ErrorsComponent';



const PasswordRestore = () => {
    const [email, setEmail] = useState('');
    const [isFormSent, setIsFormSent] = useState(false);
    const { restorePassword } = useContext(AuthContext);
    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const [errors, setErrors] = useState({});

    const handleCaptchaChange = (value) => {
        setRecaptchaValue(value);
    }
    // Calling backend API to restore the password
    const handlePasswordReset = (e) => {
        e.preventDefault();
        let newErrors = {};

        if(recaptchaValue != null && email!= '' ){
            document.body.classList.add('loading');
            setIsFormSent(true)
            restorePassword(recaptchaValue, email)
                .then((response) => {
                    document.body.classList.remove('loading');
                })
                .catch((err) => {
                    document.body.classList.remove('loading');
                });
        }
        else{
            const captchaErr =  'Por favor confirma que no eres un robot';
            const emailErr =  'Por favor introduce un email válido';

            if(recaptchaValue==null) newErrors['captcha'] = captchaErr 
            if (email==='') newErrors['form'] = emailErr

            setErrors(newErrors);
        }

    };

    return(
        <div className="form-container passrestore-container">
            <h1>Recuperar Contraseña</h1>
            <div className="restore-form">
                { isFormSent &&
                    <div className='info-text-center'>
                        Comprueba tu bandeja de correo, <span className='text-yellow-info'>incluso la de spam por si acaso</span>.
                        <br/>
                        Si perteneces a Beetested, habrás recibido un email con la información necesaria.
                    </div> 
                }
                { !isFormSent &&   
                <>
                    <div className='info-text '>
                        <h3>¿Problemas para inicar sesión?</h3>
                        Restablecer tu contraseña será sencillo.
                        Simplemente indicanos tu correo electrónico y presiona el boton enviar.
                        Te mandaremos un correo con las instrucciones.
                    </div>
                    
                    <form onSubmit={handlePasswordReset}>
                        <input
                            type="text"
                            name="email"
                            className="input email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="email"
                        />

                        <Reaptcha 
                            className='container-g-recaptcha'
                            sitekey={DRF_RECAPTCHA_PUBLIC_KEY}
                            onVerify={handleCaptchaChange}
                            theme='light'
                        />
                        <div className='errors-container'>
                            <ErrorsComponent errors={errors}/>
                        </div>

                        <button
                            className="submit-button"
                            type="submit"
                        >Enviar</button>
                        
                    </form>

                    <div className='switch-login'>
                        ¿Acabas de recordar tu contraseña?
                        <Link 
                            className='switch-login-link'
                            to='/login'
                        >Regresa e inicia sesión</Link>
                    </div>
                </>
                }
            </div>
        </div>
    )

}
export default PasswordRestore;
