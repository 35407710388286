import axios from "axios";
import { useState, useEffect, useContext} from "react";
import { API_URL } from "../../constants";
import authHeader from "../../services/auth-header";
import Wallet from "../Wallet/Wallet";
import './Profile.css';
import ProfileInvitationsContainer from "../ProfileInvitationsContainer/ProfileInvitationsContainer";
import ProfilePreview from "../ProfilePreview/ProfilePreview";
import PlatformNewsComponent from '../PlatformNewsComponent/PlatformNewsComponent';
import { PopupContext } from "../../services/popup-context";


const Profile = () => {
    const popUpStatus = useContext(PopupContext);
    
    const [user, setUser] = useState({
        username: '',
        email: '',
        birth_date: '',
        country: '',
        gender: '',
        wallet: {
            xp: 0,
            honeycoins: 0
        },
        videogames_user: []

    });

    
    useEffect(() => {
        document.body.classList.add('loading');

        authHeader().then((token) => {
            axios.get(
                API_URL + '/auth/users/profile',
                {
                    headers: token
                }
            ).then((res) => {
                res.data.videogames_user = res.data.videogames_user.map((item) => {
                    return item.videogame;
                })
                setUser(u => ({...u, ...res.data})); 
                popUpStatus.setPreviousSelectedGames(res.data.videogames_user);
                document.body.classList.remove('loading');
            }).catch((err) => {
                console.log(err);
                document.body.classList.remove('loading');
                // TODO deal with error
            });
        });
    }, []);

    return (

        <div className='user-profile-container'>
            <ProfilePreview user={user} setUser={setUser}/>
            <ProfileInvitationsContainer user={user}/>
            <div className='profile-wallet'>
                <Wallet 
                    walletValuesClassName={'profile-wallet-values'}
                    walletLabelsClassName={'profile-wallet-labels'}
                    wallet={user.wallet} 
                />
            </div>
        </div>

    );
}

export default Profile;