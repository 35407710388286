const PopupComponent = (props) => {
  return (
	<div
		style={{
			visibility: props.show ? "visible" : "hidden",
			display: props.show ? "flex" : "None",
			opacity: props.show ? "1" : "0"
		}}
		className={props.overlayClassname}
	>
		{props.childrenButton}
		{props.children}
	</div>
   )
};

export default PopupComponent;

