import { useContext, useEffect, useState } from 'react';
import authHeader from '../../services/auth-header';
import RewardsServices from '../../services/rewards-services';
import RewardsItemList from '../RewardsItemList/RewardsItemList';
import './Rewards.css'
import PlatformNewsComponent from '../PlatformNewsComponent/PlatformNewsComponent';
import PopupComponent from '../PopupComponent/PopupComponent';
import { PopupContext } from '../../services/popup-context';



const Rewards = () => {
    const popUpStatus = useContext(PopupContext);
    
    const [rewards, setRewards] = useState([]);
    const {
        getAvailableRewards
    } = RewardsServices();

    useEffect (() => {
        authHeader().then((token) => {
            getAvailableRewards(token).then( (response) =>{
                setRewards(response.data);
            })
        });
    }, [popUpStatus])

    return (

        <div className='rewards-container'>
            <div className='title'>
                <h1>Recompensas disponibles</h1>
            </div>
            <div className='demo-cards-container'>
                <RewardsItemList rewards={rewards} />
            </div>
        </div>
    );
}

export default Rewards;
