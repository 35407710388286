import { useContext, useEffect, useState } from "react";
import PopupComponent from "../PopupComponent/PopupComponent";
import "./PlatformNewsComponent.css";
import GamerProfileForm from '../GamerProfileForm/GamerProfileForm';
import HexagonContent from "../PopupContentComponents/HexagonContent";
import { PopupContext } from "../../services/popup-context";


const PlatformNewsComponent = (props) => {
    const popUpStatus = useContext(PopupContext);
    
    useEffect(()=>{
        if(popUpStatus.previousSelectedGames){
            popUpStatus.setPreviousSelectedGames([...popUpStatus.previousSelectedGames])
            popUpStatus.setSelectedVideogames([...popUpStatus.previousSelectedGames])
        }   
    }, [PopupContext]);


    const skipHandler = (e) => {
        popUpStatus.setSkip(true);
    }
    
    const childrenButton = () => {
        return (
            <div className="skip-button-container">
                <button className="skip-button" onClick={()=>skipHandler()}> Omitir </button>
            </div>
        )
    }

    const hexagonChild = ()=> {
        return (
            <HexagonContent show={popUpStatus.show && !popUpStatus.skip} closeHandler={()=>skipHandler()}>
                <div className="news-container">
                    <div className="news-info">
                        <h1 className="title yellow-word">¡Traemos Novedades!</h1>
                        <div className="news-body">
                            Para saber qué clase de <font className="yellow-word">Beetester</font> eres necesitamos que nos indiques tres de tus juegos favoritos.
                        </div>
                    </div>
                    <GamerProfileForm />
                </div>
            </HexagonContent>
        ) 
        
    }
    
    return (

        <PopupComponent
            overlayClassname={'centered-overlay'}
            show={popUpStatus.show && !popUpStatus.skip}
            children = {hexagonChild()}
            childrenButton={childrenButton()}
        />

    )

}


export default PlatformNewsComponent;