import './ProfilePreviewField.css';
import { useState } from "react";


const ProfilePreviewField = (props) => {
    /*
        Every field of a user profile will use this component.
        It receives as props:
        - Value: The value of the field itself, e.g., the username.
        - Type: The type of the field, e.g., 'text' or 'password'.
        - Validator: A function that will validate the field when changed.
    */
    const [error, setError] = useState('');
    props = {
        value: '',
        type: '',
        name: '',
        label: '',
        className: 'profile-value',
        validator: (value) => {return {isValid: true, error: value}},
        isEditing: false,
        hideLabel: false,
        editable: true,
        ...props
    }

    const validateField = (e) => {
        const element = e.target;
        const validator = props.validator;
        const { isValid, error } = validator(element.value);
        if (!isValid) {
            setError(error);
        } else {
            setError('');
        }
    }

    let inputTag = null;
    let valueText = null;
    switch (props.type) {
        case 'select':
            const options = [];
            for (let option of props.options) {
                if (option.value === props.value) {
                    valueText = option.text;
                }
                options.push(
                    <option
                        key={option.value}
                        value={option.value}
                    >
                        {option.text}
                    </option>
                );
                if (valueText == null) {
                    valueText = '-';
                }
            }
            inputTag = (
                <select
                    name={props.name}
                    value={props.value}
                    className='profile-input'
                    onChange={props.onChange}
                >
                    {options}
                </select>
            );
            break;
        case 'date':
            valueText = props.value || '-';
            inputTag = (
                <input
                    type={props.type}
                    name={props.name}
                    value={props.value}
                    max={props.max}
                    min={props.min}
                    className={'profile-input' + (error ? ' error' : '')}
                    onChange={props.onChange}
                />
            );
            break;
        default:
            valueText = props.value || '-';
            inputTag = (
                <input
                    type={props.type}
                    name={props.name}
                    value={props.value}
                    className={'profile-input' + (error ? ' error' : '')}
                    onChange={(e) => {validateField(e); props.onChange(e);}}
                    onBlur={(e) => validateField(e)}
                />
            );
    }

    const valueTag = (
        !props.isEditing || !props.editable ?
        (
            <p className={props.className}>{valueText}</p>
        ) : inputTag
    );
    const fieldLabelTag = (
        props.hideLabel && !props.isEditing ?
        null : 
        <p className='profile-label'>{props.label}</p>
    );
    
    const errorTag = (
        <div className={'error-label' + (error ? '': ' hidden')}>
            {error}
        </div>
    );

    return (
        <div className='profile-field'>
            {fieldLabelTag}
            {valueTag}
            {errorTag}
        </div>
    );
};

export default ProfilePreviewField;
