import { useState } from "react";

const Clipboard = ({ textToCopy, copyButtonText = 'Copy!', copiedButtonText = 'Copied', buttonClassName, copyTextClassName }) => {

    const [isCopied, setIsCopied] = useState(false);

    async function copyTextToClipboard(text) {
        if ('clipboard' in navigator) {
          return await navigator.clipboard.writeText(text);
        } 
        else {
          console.log('Ups something is wrong')
        }
    }

    const clickHandle = () => {
        // Asynchronously call copyTextToClipboard
        copyTextToClipboard(textToCopy)
          .then(() => {
            setIsCopied(true);
            setTimeout(() => {
              setIsCopied(false);
            }, 1500);
          })
          .catch((err) => {
            console.log(err);
          });
    }

    return (
        <>
            <input className={copyTextClassName} type="text" value={textToCopy} readOnly />
            <button className={buttonClassName} onClick={clickHandle}>
                <span>{ isCopied ? copiedButtonText : copyButtonText }</span>
            </button>
        </>
    );



}

export default Clipboard;