import './SummaryElement.css';
import { useState } from 'react';


const SummaryElement = (props) => {
    const [showHelpText, setShowHelpText] = useState(false);
    
    props = {
        label: '',
        value: '',
        href: '',
        rel: 'noreferrer',
        target: '_self',
        helpText: '',
        ...props
    }

    const valueTag = props.href ? (
        <a 
            className='summary-element-link'
            href={props.href}
            target={props.target}
            rel={props.rel}
        >
            {props.value}
        </a>
    ) : props.value;

    const toggleShowHelpText = () => {
        setShowHelpText(!showHelpText);
    }

    const helpTextTag = props.helpText ? (
        <div className='help-container'>
            <div
                className='help-action'
                onMouseEnter={toggleShowHelpText}
                onMouseLeave={toggleShowHelpText}
            >
                ?
            </div>
            <div className={'help-text' + (showHelpText ? '' : ' hidden')}>
                {props.helpText}
            </div>
        </div>
        
    ) : null;

    return (
        <div className='summary-element'>
            <div className='label'>
                {props.label}
                {helpTextTag}
            </div>
            <div className='value'>
                {valueTag}
            </div>
        </div>
    );
}

export default SummaryElement;