import './DemoView.css'
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import authHeader from '../../services/auth-header';
import TestingService from '../../services/testing.service';
import RequirementsList from '../RequirementsList/RequirementsList';
import TestInfo from '../TestInfo/TestInfo';
import DemoViewText from '../DemoViewText/DemoViewText';


const DemoView = (props) => {

    const {id} = useParams();
    props = {
        ...props
    }
    const [testInfo, setTestInfo] = useState({
        id: id,
        sessionId: '',
        videoId: '',
        name: '',
        image: {
            url: '',
            alt: '',
        },
        studio: {
            id: '',
            name: '',
            web: '',
        },
        gender: 'Acción',
        estimated_time: '15 - 30 minutos',
        xp: 100,
        honeycoins: 0,
        description: '',
        download_link: null,
        download_password: null,
        form_link: null,
        has_completed_onboarding_test: false,
    });
    const [showDescription, setShowDescription] = useState(true);
    const [showInstructions, setShowInstructions] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    const [survey, setSurvey] = useState({
        grade: 0,
        type_xp: 0,
        genre_xp: 0,
        opinion: '',
    });

    const {
        showTest,
        startTestSession
    } = TestingService();

    useEffect (() => {
        document.body.classList.add('loading');
        authHeader().then((token) => {
            showTest(token, id)
            .then((res) => {
                setTestInfo(t => ({
                    ...t,
                    ...res.data
                }));
                if (res.data.user_test != null) {
                    setTestInfo(t => ({
                        ...t,
                        sessionId: res.data.user_test.id
                    }));
                    setShowDescription(false);
                    const isCompleted = res.data.user_test.finished_at != null;
                    setShowInstructions(!isCompleted);
                    setIsCompleted(isCompleted);
                }
                document.body.classList.remove('loading');
            })
        });
    }, []);

    const startTest = () => {
        document.body.classList.add('loading');
        authHeader().then(token => {
            startTestSession(token, testInfo.id)
            .then((res) => {
                document.body.classList.remove('loading');
                setTestInfo({
                    ...testInfo,
                    sessionId: res.data.id,
                });
                setShowDescription(false);
                setShowInstructions(true);
            });
        })
    }

    return (
        <div className='test-container'>
            <div className='image-container-mobile'>
                <img src={testInfo.image.url} alt={testInfo.image.alt} />
            </div>
            <div className='column-1'>
                <TestInfo testInfo={testInfo}/>
                <RequirementsList /> {/*TODO: Actualmente los requerimientos están hardcodeados */}
            </div>
            <div className='column-2'>
                <DemoViewText 
                    testInfo={testInfo} 
                    startTest={startTest} 
                    showDescription={showDescription}
                    showInstructions={showInstructions}
                    isCompleted={isCompleted}
                />
            </div>
        </div>

    );
}

export default DemoView;
