import SummaryElement from '../SummaryElement/SummaryElement';

const RequirementsList = (props) => {
    return (
        <div className='summary-description'>
            <div className='title-container'>
                <p className='title-2'>Requisitos técnicos</p>
            </div>
            <SummaryElement 
                label='CPU'
                value='Intel i5 o similar'
            />
            <SummaryElement
                label='Memoria RAM'
                value='10GB'
            />
            <SummaryElement 
                label='Sistema operativo'
                value='Windows 10 o superior'
            />
            <SummaryElement
                label='Hardware'
                value='Cámara y micrófono'
                helpText='¡Recuerda que debes grabarte jugando para que el test sea válido!'
            />
        </div>
    )
}
export default RequirementsList;