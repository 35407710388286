import React, { useState, useContext, useRef } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { AuthContext } from '../../services/auth.service';
import UserValidator from '../../utils/user.validator';
import './ChangePassword.css'
import Reaptcha from "reaptcha";
import { DRF_RECAPTCHA_PUBLIC_KEY } from '../../constants';
import ErrorsComponent from '../ErrorsComponent/ErrorsComponent';


const ChangePassword = (props) => {
    const [password, setPassword] = useState('');
    const [rePassword, setRePassword] = useState('');
    const [errors, setErrors] = useState([]);
    const {token} = useParams()
    const [isNotAccepted, setIsNotAccepted] = useState(false)
    const [passwordChanged, setPasswordChanged] = useState(false)
    const { changePassword } = useContext(AuthContext);
    const [recaptchaValue, setRecaptchaValue] = useState(null);

    const {
        validatePassword,
        validateRePassword
    } = UserValidator();

    const handleCaptchaChange = (value) => {
        setRecaptchaValue(value);
    }
 
    // Calling backend API to restore the password
    const handleChangePassword = (e) => {
        e.preventDefault();
        const recaptcha = recaptchaValue
        let newErrors = {};
        if(recaptcha == null){
            newErrors['captcha'] = 'Por favor confirma que no eres un robot';
            setErrors(newErrors);
        }
        document.body.classList.add('loading');
        if (!errors.length && recaptchaValue) {
            changePassword(recaptchaValue, token, password)
            .then((response) => {
                console.log(response);
                // Redirecting the user to the login view
                setPasswordChanged(true)
                document.body.classList.remove('loading');
            })
            .catch((err) => { 
                setIsNotAccepted(true)
                document.body.classList.remove('loading');
            }); 
        }
    };

    const validators = {
        'password': (value) => validatePassword(value, ''), //TODO Check if the password is not equal to the username
        're-password': (value) => validateRePassword(value, password),
    };

    const validateField = (e) => {
        const element = e.target || e;
        const validator = validators[element.name] || validators.default;
        const { isValid, error } = validator(element.value);
        if (!isValid) {
            element.classList.add('error');
            errors[element.name] = error;
            
        } else {
            element.classList.remove('error');
            delete errors[element.name];
        }
        setErrors(errors);
    };

    return(
        <div className="form-container change-pass-container">
            <h1>Cambiar contraseña</h1>
            
            <div className="restore-form">
               { !isNotAccepted && !passwordChanged &&
                    <>
                        <form onSubmit={handleChangePassword}>
                            <input
                                type="password"
                                name="password"
                                className="input password"
                                value={password}
                                onChange={(e) =>{
                                    setPassword(e.target.value)
                                    validateField(e)
                                }}
                                onBlur={(e) => validateField(e)} 
                                placeholder="contraseña (mínimo 8 caracteres)"
                            />
                            <input
                                type="password"
                                name="re-password"
                                className="input password"
                                value={rePassword}
                                onChange={(e) => {
                                    setRePassword(e.target.value)
                                    validateField(e)
                                }}
                                onBlur={(e) => validateField(e)}
                                placeholder="repite la contraseña"
                            />

                            <Reaptcha 
                                className='container-g-recaptcha'
                                sitekey={DRF_RECAPTCHA_PUBLIC_KEY}
                                onVerify={handleCaptchaChange}
                                theme='light'
                            />

                            <div className='errors-container'>
                                <ErrorsComponent errors={errors}/>
                            </div>

                            <button
                                className="submit-button"
                                type="submit"
                            >Enviar</button>

                        </form>

                        <div className='switch-login'>
                            ¿Acabas de recordar tu contraseña?
                            <Link 
                                className='switch-login-link'
                                to='/login'
                            >Regresa e inicia sesión</Link>
                        </div>
                    </>
                }
                {isNotAccepted && !passwordChanged &&
                    <div className='error-info-text'>
                        Parece que ha pasado demasiado tiempo desde que has intentado recuperar tu contraseña.
                    	Por favor inténtalo de nuevo:
                        <br/>
                        <Link
                        className='switch-login-link'
                        to='/login/forgot-password'
                        >Recuperar contraseña</Link>
                    </div>
                }
                {!isNotAccepted && passwordChanged &&
                    <div className='success-info-text'>
                        <span className='success-text'>El cambio de contraseña se ha realizado con éxito</span>
                        <br/>
                    	Utiliza el enlace a continuación para iniciar sesión con tu nueva contraseña.
                        <div>
                            <Link
                            className='switch-login-link'
                            to='/login'
                            >¡Iniciar sesión ahora!</Link>
                        </div>
                    </div>
                }
                
            </div>
        </div>
    )

}
export default ChangePassword;
