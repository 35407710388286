import React, { useContext, useEffect, useState } from 'react'
import CoreServices from '../../services/core-services';
import authHeader from '../../services/auth-header';
import './GamerProfileSearchBar.css'
import { PopupContext } from '../../services/popup-context';

const GamerProfileSearchBar = (props) => {
    const [inputPlaceHolderText, setInputPlaceHolderText] = useState()

    const {
        selectedVideoGames, setSelectedVideogames,
        setResponseVideogames,
        showedList , setShowedList
    } = useContext(PopupContext);

    const {
        getVideoGamesListFiltered
    } = CoreServices();

    useEffect(()=>{
        setInputPlaceHolderText(
            'Escribe al menos las tres primeras letras del juego que buscas...'
        )
    }, [])

    const doQuery = (value) => {
        value = value.trim()
        if(value.length == 3){
            setInputPlaceHolderText(null)
            authHeader()
                .then((token)=> {
                    getVideoGamesListFiltered(token, value)
                    .then((response) => {
                        const filteredGames = response.data.filter((item) => {
                            if (!selectedVideoGames.some(e => e.id === item.id)) {
                                return item;
                            }
                        })
                        setResponseVideogames(filteredGames)
                        setShowedList(filteredGames.sort(function(item1,item2){
                            if (item1.title > item2.title) {
                                return 1;
                              }
                              if (item1.title < item2.title) {
                                return -1;
                              }
                              // a must be equal to b
                              return 0;
                        }))
                    })
                }
            )
        }
        else if(value.length >= 3 && showedList){
            
            const newFilter = showedList.filter(
                item => item.title.toLowerCase().includes(value.toLowerCase())
            )
            let finalNewList = newFilter
            const newList = []
            if (selectedVideoGames.length > 0){
                finalNewList.forEach((game) => {
                    selectedVideoGames.forEach((selectedGame) => {
                        if(game.id !== selectedGame.id){
                            if(!newList.includes(game)){
                                newList.push(game)
                            }
                                
                        }
                    })
                })
                finalNewList = newList;
            }  
            setShowedList(finalNewList.sort(function(item1,item2){
                if (item1.title > item2.title) {
                    return 1;
                  }
                  if (item1.title < item2.title) {
                    return -1;
                  }
                  // a must be equal to b
                  return 0;
            }))
        }
        else{
            setInputPlaceHolderText(
                'Escribe al menos las tres primeras letras del juego que buscas...'
            )
        }
    }
    const addSelectedVideoGame = (videogame) =>{
        const games = selectedVideoGames
        games.push(videogame)
        setSelectedVideogames(games)
        const newSelected = showedList.filter(function (item) {
            return item.id != videogame.id
        });
        setShowedList(newSelected.sort(function(item1,item2){
            if (item1.title > item2.title) {
                return 1;
              }
              if (item1.title < item2.title) {
                return -1;
              }
              // a must be equal to b
              return 0;
        }))
    }

    return (
        <div className='games-search-bar'>
            <div className='input-searchbar'>
                <input
                    className='input-videogames'
                    key='input-searchbar'
                    placeholder={inputPlaceHolderText}
                    onChange={(e) => {
                        e.preventDefault()
                        doQuery(e.target.value)
                    }}
                />
            </div>
            
                {showedList.length > 0 &&   
                    <div className='response-videogames'>                 
                        {showedList.map((item)=>(
                            <p className='game-item' key={item.id} onClick={(e)=>{addSelectedVideoGame(item)}}>
                                {item.title}
                            </p>
                        )) }
                    </div>
                }
            
        </div>
    )
    

}

export default GamerProfileSearchBar;