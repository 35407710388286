import axios from 'axios';
import CookiesService from './cookies.service';
import {
    API_URL,
    JWT_ACCESS_COOKIE_NAME,
    JWT_REFRESH_COOKIE_NAME
} from '../constants';
import { createContext, useState } from 'react';
import { PopupContext } from './popup-context';


const AuthService = () => {
    const {
        getCookie,
        setCookie,
        removeCookie,
    } = CookiesService();

    const [isAuthed, setIsAuthed] = useState(
        getCookie(JWT_ACCESS_COOKIE_NAME) !== ''
        || getCookie(JWT_REFRESH_COOKIE_NAME) !== ''
    );

    return {
        isAuthed,
        async register(recaptcha, username, email, password, referredBy) {
            const response = await axios.post(
                API_URL + '/auth/users/create',
                {recaptcha, username, email, password , 'referred_by': referredBy }
            );
            return response;
        },

        async login(username, password) {
            const response = await axios.post(
                API_URL + '/auth/login', 
                { username, password }
            )
            
            setCookie(JWT_ACCESS_COOKIE_NAME, response.data.access, 0.01);
            setCookie(JWT_REFRESH_COOKIE_NAME, response.data.refresh, 7);
            setIsAuthed(true);
        },

        async verifyEmail(token) {
            const response = await axios.post(
                API_URL + '/auth/users/verify-email',
                { token }
            );
            return response;
        },

        async restorePassword(recaptcha, email) {
            const response = await axios.post(
                API_URL + '/auth/users/forgot-password',
                { recaptcha, email }
            )
            return response;
        },

        async changePassword(recaptcha, token, password) {
            const response = await axios.post(
                API_URL + '/auth/users/change-password',
                {recaptcha, token, password }
            );
            return response;
        },

        logout() {
            removeCookie(JWT_ACCESS_COOKIE_NAME);
            removeCookie(JWT_REFRESH_COOKIE_NAME);
            setIsAuthed(false);
            PopupContext.updatePopup();
        }
    };
}

export default AuthService;

export const AuthContext = createContext();

export function AuthContextProvider({children}) {
    return (
        <AuthContext.Provider value={ AuthService() }>
            { children }
        </AuthContext.Provider>
    )
}
