import React, { useContext, useEffect, useRef, useState } from 'react'
import GamerProfileSearchBar from '../GamerProfileSearchBar/GamerProfileSearchBar';
import GamerProfileSelectedVideoGames from '../GamerProfileSelectedVideoGames/GamerProfileSelectedVideoGames';
import authHeader from '../../services/auth-header';
import CoreServices from '../../services/core-services';
import './GamerProfileForm.css'
import { PopupContext } from '../../services/popup-context';


const GamerProfileForm = (props) => {
    const popUpStatus = useContext(PopupContext);

    const [disabled, setDisabled] = useState(true)


    const {
        postVideoGamesListFiltered
    } = CoreServices();


    const sendSelectedToFront = () => {
        let difference = popUpStatus.selectedVideoGames
        if(popUpStatus.previousSelectedGames){
            difference = popUpStatus.selectedVideoGames.filter(game => !popUpStatus.previousSelectedGames.includes(game));
        }
    
        const selectedIDs = difference.map((game)=> game.id)
        authHeader().then((token)=>{
            postVideoGamesListFiltered(token, selectedIDs).then(()=>{
                popUpStatus.setHilightNews(false);
                popUpStatus.setShow(false);
            })
            
        })
        
    }

    return (
        <div className='gamer-profile-form'>
            <GamerProfileSearchBar />
            <GamerProfileSelectedVideoGames disabled ={disabled} setDisabled = {setDisabled}/>
            <div className='form-button-container'>
                <button disabled={ disabled } className='confirmation-button' onClick={()=>sendSelectedToFront()}>Confirmar</button>
            </div>
        </div>
    )
    

}

export default GamerProfileForm;