import './ProfileInvitationsContainer.css';
import Clipboard from '../Clipboard/Clipboard';
import { INVITE_URL } from '../../constants';
import HelpText from '../HelpText/HelpText';

const ProfileInvitationsContainer = ({user}) => {
    
    return (
        <div className='profile-invitations-container'>
            <HelpText className='custom-helptext'
                            helpString= {'Muy pronto recibirás recompensas por invitar a tus amigos. '+
                        	'Recuerda que los usuarios a los que invites deben verificar su email, '+
                        	'sólo así ambos obtendréis los beneficios.'
                            } 
                            helpIcon = '?'
            />

            <div className='profile-title-container'>
                <h1 className='profile-invitations-title'> Invita a testear a tus amigos </h1>
            </div>

            <div className='inside-profile-invitation-container'>
                <div className='user-invitation-clipboard'>
                    <Clipboard
                        textToCopy= { INVITE_URL + user.invitation_code}
                        buttonClassName='invite-button'
                        copyTextClassName='invite-text'
                    />
                </div>
                <div className='user-invitation-statistics'>
                        <span className="number-of-invitations-label"> Actualmente has invitado a </span>
                        <span className='number-of-invitations-value'> {user.number_of_users_invited}</span>
                        <span className="number-of-invitations-label">  beetesters.</span>
                </div>
            </div>

        </div>
    );
}

export default ProfileInvitationsContainer;