import popupStyles from "./PopupComponent.module.css"
const RectangleContent = (props) =>{
    return (
        <div className={popupStyles.popup}>
            <span className={popupStyles.close} onClick={props.closeHandler}>
                &times;
            </span>
            <h2>{props.title}</h2>
            <div className={popupStyles.content}>{props.children}</div>
        </div>
    )
}
export default RectangleContent;