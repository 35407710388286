import React from 'react';
import './BeetestedLogo.css';
import logo from '../../img/beetested_logo.svg';

const BeetestedLogo = (props) => {
    return(
        <div className={props.className}>
            <img className='logo-img' src={logo} alt='beetested logo transparent' />
        </div>
    );
};

export default BeetestedLogo;
