import React, { useEffect, useState } from 'react';
import './ErrorsComponent.css'



const ErrorsComponent = ({errors}) => {
    return (
        <div id='error-label' className='error-text'>
        { 
            Object.keys(errors).map((key, index) => ( 
                <p key={index}>{errors[key]}</p> 
            ))
        } 
        </div>
    )
}

export default ErrorsComponent;