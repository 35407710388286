import SummaryElement from '../SummaryElement/SummaryElement';

const TestInfo = ({testInfo}) => {

    return (
        <div className='summary-description'>
            <div className='title-container'>
                <p>{testInfo.name}</p>
            </div>
            <SummaryElement 
                label='Desarrollador'
                value={testInfo.studio.name}
                href={testInfo.studio.web}
                target='_blank'
            />
            <SummaryElement
                label='Género'
                value={testInfo.gender}
            />
            <SummaryElement 
                label='Tiempo estimado'
                value={testInfo.estimated_time}
            />
            <SummaryElement
                label='XP'
                value={testInfo.xp}
                helpText='La cantidad de puntos de experiencia que ganarás al completar el test con éxito.'
            />
            <SummaryElement
                label='Honeycoins'
                value={testInfo.honeycoins}
                helpText='La cantidad de honeycoins otorgadas al completar este test con éxito.'
            />
        </div>
    )

}
export default TestInfo;