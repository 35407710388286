const OnboardingTestInstructions = () => {
    return (
        <div className={'description-text'}>
            <h1>¡Parece que no has realizado el test de prueba!</h1>
            <p>
                El test de prueba es obligatorio para entrar a cualquier testeo de Beetested.
            </p>
            <h2>¿Por qué?</h2>
            <p>
                Durante cualquier testeo se graba la cara y la pantalla con el OBS.
                Para que TIMI (el modelo de IA de Beetested) pueda procesar tus reacciones y los eventos del juego, 
                los vídeos tendrán que tener el formato correcto.
            </p>
            <p>
                Por eso te pedimos que aprendas a configurar el OBS de forma que TIMI lo entienda.
                El récord de rapidez en hacer el test de prueba (que conocemos nosotros) es de <strong>
                3 minutos y 23 segundos.</strong>
            </p>
            <p>
                Esto te ayudará a formar parte de los testeos.
            </p>
            <div className={'start-test-button'}>
                <a href='https://forms.gle/UfgmYLDG93QsteUN9' target='_blank'>
                    <button className='button-start'>¡Vamos allá!</button>
                </a>
            </div>
        </div>
    )
}

export default OnboardingTestInstructions;