import { useEffect, useState } from 'react';
import RewardsItem from '../RewardsItem/RewardsItem';
import './RewardsItemList.css'



const RewardsItemList = ({rewards}) => {
    
    return (
        <div className='rewards-item-list'>
            {rewards.map((item) => (
                <RewardsItem 
                    key={item.id}
                    item={item}
                />    
            ))}
        </div>
    );
}

export default RewardsItemList;
