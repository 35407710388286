import { Navigate, Outlet } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../../services/auth.service';


const ProtectedRoute = ({
    children,
    redirectPath = '/login'
}) => {
    const { isAuthed } = useContext(AuthContext);

    return (
        isAuthed 
        ? (children ? children : <Outlet />)
        : <Navigate to={redirectPath} replace />
    );
}

export default ProtectedRoute;