import { useEffect, useState } from "react";
import authHeader from "../services/auth-header";
import { Link } from 'react-router-dom';
import axios from "axios";
import { API_URL } from "../constants";

const PopUpHook = () => {
    const [show, setShow] = useState(false);
    const [skip, setSkip] = useState(false);
    const [previousSelectedGames, setPreviousSelectedGames] = useState(null)
    const [selectedVideoGames, setSelectedVideogames] = useState([])
    const [responseVideogames, setResponseVideogames] = useState([])
    const [showedList , setShowedList] = useState([])
    const [hilightNews, setHilightNews] = useState(false);
    
    useEffect(()=>{
        authHeader().then((token) => {
            axios.get(
                API_URL + '/auth/users/profile',
                {
                    headers: token
                }
            ).then((res) => {
                res.data.videogames_user = res.data.videogames_user.map((item) => {
                    return item.videogame;
                })
                setPreviousSelectedGames(res.data.videogames_user);
                if(res.data.videogames_user.length < 3){
                    setShow(true);
                    setHilightNews(true);
                }
            }).catch((err) => {
                console.log(err);
            });
        })

    },[])

    const handleOnclick = (e)=>{    
        setShow(true);
        setSkip(false);
    }
    
    const newsLink =<><Link className={'main-item '} onClick= {(e)=>handleOnclick(e)}>
        {hilightNews && 
            <div className="link-notification"></div>
        }
        News 
        </Link>
    </>;

    return {
        show, setShow,
        skip, setSkip,
        previousSelectedGames, setPreviousSelectedGames,
        selectedVideoGames, setSelectedVideogames,
        responseVideogames, setResponseVideogames,
        showedList, setShowedList,
        newsLink,
        hilightNews, setHilightNews
    }
}

export default PopUpHook;