import React, { useContext, useEffect } from 'react';
import './App.css';
import './styles/LoadingModal.css';
import {
    Routes,
    Route,
    Link
} from 'react-router-dom';
import { AuthContextProvider } from './services/auth.service';
import Register from './components/Register/Register';
import Login from './components/Login/Login';
import ForgotPassword from './components/Login/ForgotPassword';
import NavBar from './components/NavBar/NavBar';
import Home from './components/Home/Home';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import DemoView from './components/DemoView/DemoView';
import Terms from './components/Legal/Terms';
import Privacy from './components/Legal/Privacy';
import Cookies from './components/Legal/Cookies';
import LegalDisclaimer from './components/Legal/LegalDisclaimer';
import EmailVerified from './components/Register/EmailVerified';
import ChangePassword from './components/ChangePassword/ChangePassword';
import Rewards from './components/Rewards/Rewards';
import { PopupContext } from './services/popup-context';
import PlatformNewsComponent from './components/PlatformNewsComponent/PlatformNewsComponent';
import PopUpHook from './hooks/PopUpHook';


export default function App() {
    const popUpStatus = PopUpHook();
    return (
        <AuthContextProvider>
        <PopupContext.Provider value={popUpStatus}>
        <div className="App">
            <div className="page-container">
                <div className='header'>
                    <NavBar />
                    <PlatformNewsComponent />
                </div>
                <div className="page-content">
                    <Routes>
                        {/* LOGIN AND REGISTER */}
                        <Route path="/login" element={<Login />} />
                        <Route path="/login/forgot-password" element={<ForgotPassword />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/?:bee?" element={<Register />} />
                        <Route path="/change-password/:token" element={<ChangePassword />} />
                        <Route path="/email-verify/:token" element={<EmailVerified />} />
                        
                        {/* LEGAL ROUTES */}
                        <Route path="/legal/disclaimer" element={<LegalDisclaimer />} />
                        <Route path="/legal/terms" element={<Terms />} />
                        <Route path="/legal/policies/privacy" element={<Privacy />} />
                        <Route path="/legal/policies/cookies" element={<Cookies />} />
                        
                        {/* APP ROUTES */}
                        <Route element={<ProtectedRoute redirectPath='/' />}>
                            <Route path="/home" element={<Home />} />
                            <Route path="/demo/:id" element={<DemoView />} />
                            <Route path="/rewards" element={<Rewards />} />
                        </Route>
                        <Route path="*" element={<h1>404 Not Found</h1>} />
                    </Routes>
                </div>
            </div>
        </div>
        </PopupContext.Provider>
        </AuthContextProvider>
    )
}
