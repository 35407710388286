import './DemoCard.css';
import { Link } from 'react-router-dom';

const DemoCard = (props) => {
    props = {
        href: '#',
        img: '',
        alt: '',
        title: '',
        ...props
    };

    return (
        <div className='demo-card'>
            <Link className='demo-card-link' to={props.href}>
                <div className='demo-image'>
                    <img src={props.img} alt={props.alt} />
                </div>
                <div className='demo-title'>
                    <h1>{props.title}</h1>
                </div>
            </Link>
        </div>
    );
};

export default DemoCard;
