const {
    REACT_APP_ENV_NAME,
    REACT_APP_API_URL,
    REACT_APP_GEARS_URL,
    REACT_APP_INVITE_URL,
    REACT_APP_DRF_RECAPTCHA_PUBLIC_KEY,
    REACT_APP_DRF_RECAPTCHA_TESTS_PUBLIC_KEY,
    REACT_APP_ENV,
} = process.env;


export const API_URL = REACT_APP_API_URL;
export const INVITE_URL = REACT_APP_INVITE_URL;
export const GEARS_URL = REACT_APP_GEARS_URL;
export const JWT_ACCESS_COOKIE_NAME = 'jwt_access_token';
export const JWT_REFRESH_COOKIE_NAME = 'jwt_refresh_token';

// GreCAPTCHA public token depends on the env running
let recapchaPublicKey =  REACT_APP_DRF_RECAPTCHA_PUBLIC_KEY 
if (REACT_APP_ENV_NAME === 'dev'){  //If the env is develop it will take vales of test
    recapchaPublicKey = REACT_APP_DRF_RECAPTCHA_TESTS_PUBLIC_KEY
}
export const DRF_RECAPTCHA_PUBLIC_KEY =  recapchaPublicKey;

export const ENV_NAME = REACT_APP_ENV;
// Adjust of chunk size of files to 10 mb
export const FILE_CHUNK_SIZE = 10_000_000 //Size in Bytes

