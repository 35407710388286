import DOMPurify from 'dompurify';
const DemoViewTextDescription = ({testInfo, startTest})=>{
    return (
        <>
            <div className={'description-text'}>
                <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(testInfo.description)}} />
            </div>
            <div className={'start-test-button'}>
                <button className='button-start' onClick={startTest}>¡Empezar test!</button>
            </div>
        </>
    )
}

export default DemoViewTextDescription;