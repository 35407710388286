import '../../styles/Legal.css';


const Privacy = () => {
    return (
        <div className='legal-text-container'>
            <h1 className='title'>
                POLÍTICA DE PRIVACIDAD
            </h1>
            <p className='paragraph'>
                En BEETESTED, S.L., (en adelante, BEETESTED) estamos firmemente
                comprometidos con el derecho a la privacidad de todos nuestros usuarios. Por
                tanto, queremos que te sientas seguro/a y tengas plena confianza en que
                respetamos tus datos y los mantendremos seguros, tanto en el uso de nuestro
                servicio, como en cualquier interacción que tengas con nosotros.
            </p><p className='paragraph'>
                Es por ello por lo que queremos compartir contigo que hemos preparado esta
                Política de Privacidad y Protección de Datos para explicarte de manera clara y
                sencilla cómo y por qué tratamos tus datos personales y cualquier otra información
                que permita identificarte. Te animamos a que la leas detenidamente y en caso de
                que tengas dudas nos consultes.
            </p><p className='paragraph'>
                BEETESTED se reserva el derecho a modificar la presente política para adaptarla a
                novedades legislativas o jurisprudenciales, así como modificaciones propias del
                servicio. En estos casos, anunciaremos en esta Política de Privacidad los cambios
                introducidos con razonable antelación a su puesta en práctica.
            </p><p className='paragraph'>
                Asimismo, te informamos de que la edad mínima para el uso de LA PLATAFORMA es
                de 18 años y que BEETESTED dispone de mecanismos para su verificación.
            </p>
            <h2 className='title'>
                Responsable del tratamiento
            </h2>
            <p className='paragraph'>
                El responsable del tratamiento de los datos recabados por este medio, así como los
                que se generen como consecuencia del uso de nuestro servicio es:
            </p><p className='paragraph'>
                <strong>BEETESTED, S.L.</strong>
            </p>
            <ul>
                <li>C.I.F.: B72511413</li>
                <li>Dirección: Calle Maiquez, 21, 6-8, 28009 - Madrid</li>
                <li>Contacto: <a href="mailto:hello@beetested.com">hello@beetested.com</a></li>
            </ul>
            <h2 className='title'>
                Información que recopilamos
            </h2>
            <p className='paragraph'>
                En BEETESTED, con carácter general recopilamos y obtenemos información de
                carácter personal de dos formas:
            </p>
            <ol>
                <li className='strong'>Datos que nos proporcionas directamente</li>
                <ul>
                    <li>
                        Cuando nos facilitas tus datos a través del formulario de
                        contacto de la PLATAFORMA:
                        <ul>
                            <li>Nombre (opcional)</li>
                            <li>Email</li>
                        </ul>
                    </li>
                    <li>
                        Cuando aceptas nuestras Condiciones de Uso y pasas a ser
                        usuario de la PLATAFORMA:
                        <ul>
                            <li>Nombre (opcional)</li>
                            <li>Nacionalidad (opcional)</li>
                            <li>Edad</li>
                            <li>Gustos en videojuegos</li>
                            <li>Cuando subes un vídeo a la PLATAFORMA, recabamos tu imagen, gestos y voz.</li>
                        </ul>
                    </li>
                </ul>
                <li className='strong'>
                    Datos que nos proporcionas de manera indirecta e información
                    que recopilamos automáticamente:
                </li>
                <ul>
                    <li>
                        Al visitar y utilizar la PLATAFORMA, recabamos automáticamente a través de 
                        cookies la siguiente información:
                        <ul>
                            <li>Ubicación aproximada</li>
                            <li>Navegador utilizada</li>
                            <li>Sistema Operativo (SO)</li>
                            <li>Dirección IP</li>
                            <li>Hora de conexión</li>
                            <li>
                                Otra información obtenida mediante la utilización de cookies,
                                contadores de visitas a la PLATAFORMA y otras tecnologías,
                                que incluye información de publicidad. Puedes consultar información
                                adicional en nuestra <a href='/policies/cookies'>Política de Cookies</a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        Cuando subes un vídeo a la PLATAFORMA, a través de nuestro modelo de machine learning
                        se infiere la siguiente información:
                        <ul>
                            <li>Emociones</li>
                            <li>Gestos</li>
                        </ul>
                    </li>
                </ul>
            </ol>
            <h2 className='title'>
                Finalidades y legitimación para el tratamiento de tus datos
            </h2>
            <p className='paragraph'>
                A continuación, te informamos de las finalidades para las que tratamos tus datos
                personales, junto a la base legal que nos legitima para ello:
            </p>
            <ol>
                <li className='strong'>Consentimiento</li>
                <ul>
                    <li>
                        Para poder contactar contigo cuando nos facilitas tus datos en el
                        formulario de contacto de LA PLATAFORMA, necesitamos tu
                        consentimiento para poder informarte sobre el servicio que
                        ofrecemos.
                    </li>
                </ul>
                <li className='strong'>Ejecución de contrato</li>
                <ul>
                    <li>
                        <p className='paragraph'>
                            Una vez aceptes nuestras Condiciones de Uso, podrás utilizar LA
                            PLATAFORMA como testeador de videojuegos y podrás grabarte
                            jugando y subir tu video a LA PLATAFORMA. En estos casos,
                            podremos tratar tus reacciones, gestos y emociones durante la
                            sesión de juego inferidos a través de nuestro modelo de
                            Inteligencia Artificial. Puedes obtener más información sobre el
                            funcionamiento nuestra Inteligencia Artificial en el apartado&nbsp;
                            <a href="#iaDescription">Descripción del modelo de Inteligencia Artificial (IA)</a>.
                        </p><p className='paragraph'>
                            Ten en cuenta que, como usuario de LA PLATAFORMA puedes
                            hacer uso de la misma y acceder a los juegos que estén
                            disponibles, pero es necesario que te grabes y subas el vídeo para
                            para poder dar tu opinión sobre la experiencia de juego y acceder
                            al sistema de recompensas.
                        </p>
                    </li>
                </ul>
                <li className='strong'>Nuestros intereses legítimos</li>
                <ul>
                    <li>
                        <p className='paragraph'>
                            Hacemos un perfil sobre ti a través un algoritmo que nos sirve
                            para recomendarte videojuegos que se adapten a tus gustos e
                            intereses, en función de tu uso o interacciones en LA
                            PLATAFORMA. Por ejemplo, si habitualmente accedes a
                            videojuegos de deportes, la recomendación que te haremos irá en
                            ese sentido. La información sobre estas recomendaciones se irá
                            actualizando según vayas jugando a un juego u otro.
                        </p><p className='paragraph'>
                            Teniendo en cuenta el tipo de perfil que se realiza, y que éste no
                            produce efectos jurídicos sobre ti, ni te afecta significativamente,
                            entendemos que es adecuado y te ayudará a tener una mejor
                            experiencia de uso de LA PLATAFORMA.
                        </p><p className='paragraph'>
                            No obstante, si no deseas que ajustemos las recomendaciones de
                            videojuegos a tus gustos e intereses, puedes oponerte según el
                            procedimiento que se indica en el apartado <a href="#rights">Tus derechos</a>,
                            así como solicitar información adicional sobre nuestros intereses.
                        </p><p className='paragraph'>
                            Si decides que no usemos esta información para recomendarte
                            determinados tipos de videojuegos, seguirás recibiendo
                            recomendaciones, pero estos no se basarán en la categoría de
                            videojuegos que más accedas.
                        </p>
                    </li>
                </ul>
            </ol>
            <h2 className='title' id="iaDescription">
                Descripción del modelo de Inteligencia Artificial
            </h2>
            <p className='paragraph'>
                Con el objetivo de ser transparentes, a continuación, te informamos sobre el
                funcionamiento de la tecnología que utilizamos:
            </p><p className='paragraph'>
                El sistema recibe una imagen estática del Usuario. De cada segundo de vídeo,
                sacamos un número de frames que pasan por el modelo de IA. El resultado es la
                localización de la cara si la detecta, así como un porcentaje para cada emoción
                básica (neutral, feliz, triste, enfadado, asustado, sorprendido o disgustado).
            </p>
            <h2 className='title'>
                Comunicación de datos a terceros
            </h2>
            <p className='paragraph'>
                En determinadas ocasiones como, por ejemplo, si incumples las Condiciones de Uso
                o la Obligación de Confidencialidad, podremos comunicar tus datos al Cliente de
                BEETESTED titular del Videojuego.
            </p><p className='paragraph'>
                Los datos personales que obtenemos serán utilizados exclusivamente para la
                consecución de las finalidades indicadas en esta Política de Privacidad y no
                compartiremos tu información con ningún tercero salvo que concurriera alguna
                obligación legal que hiciera necesaria esa comunicación a Autoridades
                Administrativas, Fuerzas y Cuerpos de Seguridad o Jueces y Tribunales.
            </p><p className='paragraph'>
                Asimismo, te informamos que tampoco se prevé la realización de Transferencias
                Internacionales de tus datos personales.
            </p><p className='paragraph'>
                La información que compartimos con nuestros clientes es agregada, sin
                conclusiones sacadas a partir de la información que recopilamos sobre ti que
                permitan tu identificación por parte de un tercero.
            </p>
            <h2 className='title'>
                Conservación de tus datos
            </h2>
            <p className='paragraph'>
                Cuando nos envías tus datos a través del formulario de contacto, los conservaremos
                por un plazo de dos (2) años si finalmente no te registras en LA PLATAFORMA.
            </p><p className='paragraph'>
                Si decides registrarte, mediante la aceptación de los Condiciones de Uso,
                conservaremos los datos que nos proporciones directamente, así como los que
                obtenemos de forma indirecta o automáticamente mientras tengas la condición de
                usuario registrado.
            </p><p className='paragraph'>
                Transcurridos el plazo y/o de conservación indicados, tus datos permanecerán
                bloqueados, mediante la adopción de medidas técnicas y organizativas, para
                impedir su tratamiento, incluyendo su visualización, excepto para la puesta a
                disposición de la información a Jueces y Tribunales, el Ministerio Fiscal o las
                Administraciones Públicas competentes, para la exigencia de posibles
                responsabilidades derivadas del tratamiento y solo por el plazo legal que
                corresponda.
            </p>
            <h2 className='title'>
                Seguridad de tus datos
            </h2>
            <p className='paragraph'>
                En cumplimiento de nuestra obligación de guardar secreto respecto de los datos de
                carácter personal recogidos, así como de nuestro deber de custodiarlos, hemos
                implantado medidas de seguridad diseñadas para garantizar la seguridad de tus
                datos personales y evitar su alteración, pérdida y tratamiento y/o acceso no
                autorizado, según el estado de la tecnología, la naturaleza, la naturaleza de los
                datos que manejamos y los riesgos a que están expuestos, ya provengan de la
                acción humana o del medio físico o natural de acuerdo con lo establecido en la
                normativa aplicable.
            </p><p className='paragraph'>
                Nos preocupamos por tu privacidad reforzando la confidencialidad y la integridad de
                la información que tratamos y manteniendo continuamente procesos de supervisión
                y control de nuestros procesos para asegurar el respeto a tu privacidad y a la
                seguridad de tus datos.
            </p>
            <h2 className='title' id='rights'>
                Tus derechos
            </h2>
            <p className='paragraph'>
                El ejercicio de los derechos en materia de protección de datos es gratuito y, con el
                objetivo de otorgar la mayor transparencia, te facilitamos más información sobre
                estos derechos:
            </p><p className='paragraph'>
                <strong>Derecho de acceso, rectificación y supresión:</strong> Puedes consultar tus datos
                personales solicitando una copia de estos, así como solicitar la rectificación de datos
                inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los
                datos ya no sean necesarios para los fines que fueron recogidos.
            </p><p className='paragraph'>
                <strong>Derecho de oposición:</strong> En determinadas circunstancias y por motivos
                relacionados con tu situación particular, podrás oponerte al tratamiento de tus
                datos. En ese caso, dejaremos de tratar los datos salvo por motivos legítimos
                imperiosos, o el ejercicio o la defensa de posibles reclamaciones.
                Derecho de limitación al tratamiento: En determinadas circunstancias, podrás
                solicitar la limitación del tratamiento, de modo que solo utilizaremos tus datos en
                los casos autorizados por la Ley.
            </p><p className='paragraph'>
                <strong>Derecho a la portabilidad:</strong> Cuando los datos se recogen en un formato
                estructurado, podrás pedir la transmisión directa de tus datos a otros responsables
                o recibir tus datos en formato electrónico, para almacenarlos en dispositivos
                propios, sin necesidad de transmitirlos a otros responsables.
            </p><p className='paragraph'>
                <strong>Derecho a retirar el consentimiento prestado:</strong> Cuando el tratamiento se base
                en tu consentimiento, podrás retirarlo en cualquier momento sin que ello afecte a la
                licitud del tratamiento realizado con anterioridad a la retirada.
            </p><p className='paragraph'>
                Podrás ejercitar tus derechos de las siguientes formas:
            </p>
            <ul>
                <li>Por correo electrónico: <a href="mailto:hello@beetested.com">hello@beetested.com</a></li>
                <li>Dirigiendo un escrito a la dirección Calle Maiquez 21, 6-8, 28009, Madrid</li>
            </ul>
            <p className='paragraph'>
                Por último, en caso de que consideres que los tratamientos realizados por
                BEETESTED no se ajustan a la legalidad, puedes presentar reclamación ante la
                Agencia Española de Protección de Datos (AEPD).
            </p>
        </div>
    )
};

export default Privacy;