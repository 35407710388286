const UserValidator = () => {
    return {
        validateUsername: (value) => {
            const correct_value = value.replace(/[^a-zA-Z_0-9]/g);
            const isValid = value.length >= 4 && value === correct_value;
            const tooShort = value.length < 4;
            const error = isValid
                ? ''
                : (tooShort
                    ? 'El nombre es demasiado corto'
                    : 'El nombre sólo puede contener letras, números y _'
                );
            return { isValid, error };
        },
        validateEmail: (value) => {
            const re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
            const isValid = re.test(value);
            const error = isValid ? '' : 'El email no es válido';
            return { isValid, error };
        },
        validatePassword: (value, username = '') => {
            const tooShort = value.length < 8
            const equalsUsername = username && value.toLowerCase() === username.toLowerCase();
            const re = /(?=.*[0-9 -/:-@[-`{-~]{1,})/;
            const isValid = !tooShort && !equalsUsername && re.test(value);
            let error = tooShort 
                ? 'La contraseña debe tener al menos 8 caracteres'
                : (equalsUsername
                    ? 'La contraseña y el nombre usuario no pueden coincidir'
                    : (isValid
                        ? ''
                        : 'La contraseña debe contener al menos una letra y un número o un caracter especial'
                    )
                );
            return { isValid, error };
        },
        validateRePassword: (value, password) => {
            const isValid = value === password;
            const error = isValid ? '' : 'Las contraseñas no coinciden';
            return { isValid, error };
        },
    };
}

export default UserValidator;