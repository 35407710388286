import { useEffect, useState } from "react";
import './CollapsibleIconComponent.css'

const CollapsibleIconComponent = ({collapsibleList}) => {
    const [collapse, setCollapse] = useState(true);
    const [addClassName, setAddClassName]= useState('');

    const toggle = () =>{
        if(collapse){
            setAddClassName('change')
        }
        else{
            setAddClassName('')
        }
        setCollapse(!collapse)
    }
    
    return (
         <div className="desplegable-links"> 

            <div className={addClassName + " collapsible-icon"} onClick={()=>{toggle()}}>
                <div className="bar1"/>
                <div className="bar2"/>
                <div className="bar3"/>
            </div>

            <div className={addClassName +" collapsible-list"}>
                {                        
                    collapsibleList.map((link, index) =>
                        <li key={index} className='link'>{link}</li>
                    )
                }
            </div>
    
        </div>
        
    )
}

export default CollapsibleIconComponent;