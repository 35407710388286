import React, { useState, useContext, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { AuthContext } from '../../services/auth.service';
import { useNavigate } from 'react-router-dom';
import UserValidator from '../../utils/user.validator';
import './Register.css';
import Reaptcha from "reaptcha";
import { DRF_RECAPTCHA_PUBLIC_KEY } from '../../constants';
import ErrorsComponent from '../ErrorsComponent/ErrorsComponent';


const Register = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rePassword, setRePassword] = useState('');
    const [isRegistered, setIsRegistered] = useState(false);
    const optionalParams = new URLSearchParams(useLocation().search);
    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const [errors, setErrors] = useState({});

    const {
        validateUsername,
        validateEmail,
        validatePassword,
        validateRePassword
    } = UserValidator();
    const navigate = useNavigate();

    const validators = {
        'username': (value) => validateUsername(value),
        'email': (value) => validateEmail(value),
        'password': (value) => validatePassword(value, username),
        're-password': (value) => validateRePassword(value, password),
    };

    const { isAuthed, register } = useContext(AuthContext);

    useEffect(() => {
        if (isAuthed) {
            navigate('/home');
        }
    }, [isAuthed, navigate]);

    const thankYouTag = (
        <div className={'thank-you ' + (isRegistered ? '' : ' hidden')}>
            <p>¡Gracias por registrarte, <span className='username-bold'></span>{username}!</p>
            <p>Ya puedes<a className='switch-login-link' href="/login">iniciar sesión</a> para convertirte en <span className='color-yellow strong'>beetester</span>.</p>
        </div>
    );

    const handleCaptchaChange = (value) => {
        setRecaptchaValue(value);
    }

    const handleRegister = (e) => {
        let referredBy = null;
        e.preventDefault();
        document.body.classList.add('loading');

        // Re-initialize errors to remove conflict error before re-send

        setErrors({});

        // Validate all fields
        validateGreCAPTCHA();
        e.target.childNodes.forEach((c) => {
            if (c.name in validators) {
                validateField(c);
            }
        });
        
        setErrors({...errors})
     
        if (!errors.length && recaptchaValue) {
            // Get the invitation code from optional params in URL
            if(optionalParams.has('bee')){
                referredBy = optionalParams.get('bee')
            }
            
            register(recaptchaValue, username, email, password, referredBy)
            .then((res) => {
                setIsRegistered(true);
                document.body.classList.remove('loading');
            })
            .catch((err) => {
                
                const response = err.response.data;
                const field_error = response.field_error
                const element =  e.target.children[field_error]
                if ( field_error === 'email') {
                    updateErrorLabel(field_error,'El email ya está en uso.');
                    element.classList.add('error');
                    
                }
                if (field_error === 'username') {
                    updateErrorLabel(field_error,'El nombre de usuario ya está en uso.');
                    element.classList.add('error');
                }

                document.body.classList.remove('loading');
            });
        } else {
            document.body.classList.remove('loading');
        }
    };

    const updateErrorLabel = (errorField, msg) => {
        errors[errorField] = msg
    }

    const validateField = (e, newErrors) => {
        const element = e.target || e;
        const validator = validators[element.name] || validators.default;
        const { isValid, error } = validator(element.value);
        if (!isValid) {
            element.classList.add('error');
            errors[element.name] = error;
        } else {
            element.classList.remove('error');
            delete errors[element.name];
        }
        updateErrorLabel();
    };
    const validateGreCAPTCHA = () =>{
        if(recaptchaValue == null){
            errors['captcha'] = 'Por favor confirma que no eres un robot';
        }
        else{
            delete errors['captcha'];
        }
    }

    return(
        <>
            { !isAuthed && <div className="form-container">
                <h1>Regístrate</h1>
                <div className={'register-form' + (isRegistered ? ' hidden' : '')}>
                    <form id='register-form' onSubmit={handleRegister}>
                        <input
                            type="text"
                            name="username"
                            className="input username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            onBlur={(e) => validateField(e)}
                            placeholder="nickname"
                        />
                        <input
                            type="text"
                            name="email"
                            className="input email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onBlur={(e) => validateField(e)}
                            placeholder="email"
                        />
                        <input
                            type="password"
                            name="password"
                            className="input password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            onBlur={(e) => validateField(e)}
                            placeholder="contraseña (mínimo 8 caracteres)"
                        />
                        <input
                            type="password"
                            name="re-password"
                            className="input password"
                            value={rePassword}
                            onChange={(e) => setRePassword(e.target.value)}
                            onBlur={(e) => validateField(e)}
                            placeholder="repite la contraseña"
                        />
                        <div className='checkbox-container'>
                            <div className='checkbox'>
                                <input
                                    id="legal-age-check"
                                    type="checkbox"
                                    className="input checkbox"
                                    required
                                    name="legal-age"
                                />
                            </div>
                            <div className='label legal-age-check'>
                                <label>
                                    Declaro que soy mayor de 18 años.
                                </label>
                            </div>
                        </div>
                        <div className='checkbox-container checkbox-container-2'>
                            <div className='checkbox'>
                                <input
                                    id="accept-terms-check"
                                    type="checkbox"
                                    className="input checkbox"
                                    required
                                    name="accept-terms"
                                />
                            </div>
                            <div className='label'>
                                <label className="check-label">
                                    Acepto los&nbsp;
                                    <a className='legal-link color-yellow' href='/legal/terms' target='_blank'>términos y condiciones de uso</a>
                                    &nbsp;y la&nbsp;
                                    <a className='legal-link color-yellow' href='/legal/policies/privacy' target='_blank'>política de privacidad</a>.
                                </label>
                            </div>
                        </div>
                        <Reaptcha 
                            className='container-g-recaptcha'
                            sitekey={DRF_RECAPTCHA_PUBLIC_KEY}
                            onVerify={handleCaptchaChange}
                            theme='light'
                        />
                        <div className='errors-container'>
                            <ErrorsComponent errors={errors}/>
                        </div>
                        
                        <button
                            className="submit-button"
                            type="submit"
                        >Enviar</button>
                    </form>
                </div>
                {thankYouTag}
                <div className='switch-login'>
                    ¿Ya tienes cuenta?
                    <Link 
                        className='switch-login-link'
                        to='/login'
                    >Inicia sesión</Link>
                </div>
            </div>
        }
        </>

    );
};

export default Register;
