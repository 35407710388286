import '../../styles/Legal.css';


const LegalDisclaimer = () => {
    return (
        <div className='legal-text-container'>
            <h1 className='title'>
                AVISO LEGAL
            </h1>
            <h2 className='title'>
                INFORMACIÓN DEL TITULAR
            </h2>
            <p className='paragraph'>
                En cumplimiento de la Ley 34/2002, de 11 de julio, de la Ley de Servicios de la Sociedad de la
                Información y de Comercio Electrónico, los Usuarios de Internet (en adelante, el Usuario o los
                Usuarios) quedan informados de que este Aviso Legal y sus condiciones regulan el acceso público
                que BEETESTED, S.L., domicilio social en Calle Maiquez 21, 6-8, 28009 - Madrid, con email de 
                contacto <a href="mailto:hello@beetested.com">hello@beetested.com</a> y con CIF 72511413,
                que figura inscrita en el Registro Mercantil de Madrid, al Tomo 44018, folio 101, hoja M-776448,
                inscripción primera (en adelante, BEETESTED), en lo que se refiere a la creación de un Perfil de Usuario
                y al acceso del área privada de la Plataforma de BEETESTED (en adelante, LA PLATAFORMA).
            </p><p className='paragraph'>
                Las condiciones que aquí se recogen son aplicables a todas las formas de acceso que se hagan a LA
                PLATAFORMA, incluyendo Internet, vía móvil o cualquier otro dispositivo. Asimismo, este Aviso
                Legal será de aplicación de forma conjunta con la Política de Privacidad, la Política de Cookies, las
                Condiciones de Uso y cualquier otro documento que regule aspectos de LA PLATAFORMA que sea
                suscrito entre los Usuarios y BEETESTED, sin que uno prevalezca sobre otro.
            </p>
            <h2 className='title'>
                ACEPTACIÓN
            </h2>
            <p className='paragraph'>
                Desde el momento en el que los Usuarios entran y usan LA PLATAFORMA están aceptando el Aviso
                Legal de BEETESTED, por lo que el acceso a LA PLATAFORMA se encuentra condicionado a la
                previa lectura y aceptación por parte de cualquier Usuario de los términos, condiciones,
                comunicaciones, advertencias y avisos legales. Si algún Usuario no estuviese de acuerdo con el
                contenido o parte del contenido del presente Aviso Legal deberá abandonar LA PLATAFORMA, no
                pudiendo acceder ni disponer de los productos y los servicios que en éstos se ofrecen.
            </p><p className='paragraph'>
                El Usuario declara ser mayor de edad y/o disponer de la capacidad jurídica suficiente para vincularse
                con el Aviso Legal, o bien haber obtenido la autorización de la persona y/o institución que le tenga a
                su cargo para aceptarlo. Igualmente, acepta de forma expresa y sin excepciones que el acceso y la
                utilización de LA PLATAFORMA, de sus servicios y de los contenidos tiene lugar bajo su única y
                exclusiva responsabilidad. Cualquier utilización distinta a la autorizada está expresamente prohibida.
            </p><p className='paragraph'>
                La entrada y visualización del Área Pública de LA PLATAFORMA es libre, gratuita y no requiere
                suscripción o registro previos. No obstante, el acceso a los servicios, productos y contenidos
                ofrecidos por BEETESTED en el Área Privada de LA PLATAFORMA se encuentran sujeto a unas
                condiciones especiales de uso, que en todo caso podrán ser consultadas por el Usuario antes de su
                activación, así como al registro previo por parte del Usuario, tal y como se determina en las
                <a href='/legal/terms'>Condiciones de Uso</a> de LA PLATAFORMA.
            </p><p className='paragraph'>
                BEETESTED se reserva el derecho de efectuar sin previo aviso los cambios y las modificaciones que
                considere oportunas en LA PLATAFORMA y en el Aviso Legal, poniéndolo en su caso en
                conocimiento de los Usuarios cuando esto fuese posible.
            </p>
            <h2 className='title'>
                GARANTÍAS Y RESPONSABILIDAD DE BEETESTED
            </h2>
            <p className='paragraph'>
                BEETESTED tiene el derecho de suspender temporalmente y sin preaviso la accesibilidad a LA
                PLATAFORMA, así como a reservarse el derecho de prestación o cancelación de los servicios y los
                productos, intentando ponerlo previamente en conocimiento de los Usuarios, siempre que las
                circunstancias así se lo permitan.
            </p><p className='paragraph'>
                BEETESTED ofrecerá los productos y prestará los servicios que se ofrecen en LA PLATAFORMA,
                garantizando el secreto de las comunicaciones que pudieran existir con el Usuario y respondiendo a
                las reclamaciones que pudieran plantearse.
            </p><p className='paragraph'>
                El Usuario entiende y acepta que LA PLATAFORMA ha sido creada y desarrollada de buena fe por
                BEETESTED y la ofrece en su estado actual a los Usuarios.
            </p><p className='paragraph'>
                BEETESTED no se responsabiliza por aquellos problemas de acceso y/o del acceso incorrecto por
                parte de los Usuarios a LA PLATAFORMA debidos al uso de un sistema informático (software y
                hardware) no adecuado para el mismo, así como tampoco por el uso de determinados navegadores,
                restricciones de acceso a Internet, dispositivos, sistemas operativos, etc. y/o cualesquiera otras
                circunstancias que puedan afectar al acceso a LA PLATAFORMA por parte de los Usuarios.
            </p><p className='paragraph'>
                No obstante, BEETESTED no controla ni garantiza la ausencia de virus ni de otros elementos en los
                contenidos de LA PLATAFORMA que puedan producir alteraciones en el sistema informático
                (software y hardware) del Usuario, en sus dispositivos o en los documentos electrónicos y ficheros
                almacenados en sus sistemas informáticos. BEETESTED se exime de cualquier responsabilidad por
                los daños y perjuicios de toda naturaleza que puedan deberse a la presencia de virus en los
                contenidos y los elementos de LA PLATAFORMA que puedan producir alteraciones en el sistema
                informático, dispositivo, equipo, documentos electrónicos, ficheros, etc.
            </p><p className='paragraph'>
                BEETESTED tiene el derecho de oponerse y/o eliminar cualquier contenido que infrinja este Aviso
                Legal, sus Políticas de Privacidad y Cookies y/o sus Condiciones de Uso, desde el momento en el
                que tenga conocimiento efectivo, así como de denegar o cancelar el acceso a LA PLATAFORMA a
                cualquier persona o entidad según el criterio de BEETESTED. Por ello, los Usuarios podrán notificar
                a BEETESTED cualquier infracción del Aviso Legal, sus Políticas de Privacidad y Cookies y/o sus
                Condiciones de Uso a través de la siguiente dirección: <a href="mailto:hello@beetested.com">hello@beetested.com</a>
            </p>
            <h2 className='title'>
                OBLIGACIONES DEL USUARIO
            </h2>
            <p className='paragraph'>
                El Usuario está obligado a realizar un uso lícito de LA PLATAFORMA y de los productos y servicios
                ofrecidos de acuerdo con la legislación vigente, respetando los derechos de Propiedad Intelectual e/o
                Industrial y/o de cualquier otro tipo tanto de BEETESTED como de terceros.
            </p><p className='paragraph'>
                El Usuario se compromete a no realizar a través de LA PLATAFORMA y/o los servicios ofrecidos por
                BEETESTED ninguna acción que cause daño o alteraciones, ni entorpezca el funcionamiento de LA
                PLATAFORMA, sin causar problemas técnicos, no transfiriendo elementos portadores de virus
                informáticos o de dañar, interferir o interceptar total o parcialmente LA PLATAFORMA, así como a no
                intervenir o alterar el correo electrónico de otros Usuarios.
            </p><p className='paragraph'>
                Los Usuarios no podrán establecer ningún vínculo y/o enlace a LA PLATAFORMA desde cualquier
                otra web y/o aplicación sin el previo y expreso consentimiento de BEETESTED o salvo que se
                indique lo contrario en LA PLATAFORMA. Asimismo, los Usuarios se comprometen a que no
                utilizarán elementos prohibidos como virus, spam o enlaces a los mismos.
            </p><p className='paragraph'>
                El Usuario garantiza que las actividades que realice en LA PLATAFORMA se adecuarán a la ley, la
                moral, el orden público, las buenas costumbres, comprometiéndose a que en ningún caso resultarán
                ofensivas y/o dañinas para BEETESTED, los Usuarios de LA PLATAFORMA y/o cualquier tercero.
            </p>
            <h2 className='title'>
                PROTECCIÓN DE DATOS Y COOKIES
            </h2>
            <p className='title'>
                BEETESTED respetará, en todo momento, tanto lo dispuesto en la Ley Orgánica 3/2018, de 5 de
                diciembre, de Protección de Datos Personales y garantía de los derechos digitales, y en el
                Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016
                (Reglamento General de Protección de Datos), como lo previsto en toda la normativa vigente en
                materia de protección de datos de carácter personal y en lo referente al uso de Cookies. Los
                Usuarios pueden ampliar toda esta información en la&nbsp;
                <a href='/legal/policies/privacy'>Política de Privacidad</a> y en la&nbsp;
                <a href='/legal/policies/cookies'>Política de Cookies</a> de LA PLATAFORMA.
            </p>
            <h2 className='title'>
                ENLACES Y VÍNCULOS
            </h2>
            <p className='paragraph'>
                Los enlaces con otros sitios web que pudieran existir en LA PLATAFORMA, así como el uso que el
                Usuario pueda hacer de los mismos, están sujetos, en lo que corresponda, al presente Aviso Legal
                y/o a los términos concretos que dichos sitios web y/o aplicaciones dispongan. BEETESTED no
                garantiza la veracidad, fiabilidad, precisión, oportunidad o conveniencia de la información
                suministrada a los Usuarios por terceras personas.
            </p><p className='paragraph'>
                Por lo tanto, si LA PLATAFORMA contuviese vínculos o enlaces con otros sitios web y/o aplicaciones
                no gestionadas por BEETESTED, BEETESTED manifiesta que no ejerce control alguno sobre esos
                sitios web y/o aplicaciones, ni responde o es responsable del contenido de los mismos. Los enlaces
                que LA PLATAFORMA pudiera contener se ofrecerán, únicamente, a modo de referencias
                informativas, sin ningún tipo de valoración sobre los contenidos, servicios o productos ofrecidos
                desde los mismos.
            </p><p className='paragraph'>
                BEETESTED se exonera de toda responsabilidad en relación con los productos y/o servicios de
                dichos terceros frente a cualesquiera reclamaciones de cualquier naturaleza y demandas que
                pudieran interponerse en relación con los mismos por parte de los Usuarios.
            </p><p className='paragraph'>
                A menos que se haga una mención de forma expresa, en ningún caso los enlaces serán
                considerados como recomendación, patrocinio o distribución por parte de BEETESTED de los
                productos, servicios o contenidos de titularidad de terceros, ofrecidos por éstos o en cualquier forma
                divulgados por los mismos.
            </p>
            <h2 className='title'>
                PROPIEDAD INTELECTUAL E INDUSTRIAL
            </h2>
            <p className='paragraph'>
                BEETESTED, como titular o cesionaria en exclusiva o no exclusiva, ostenta los derechos de
                Propiedad Intelectual e Industrial sobre todos los elementos y contenidos de LA PLATAFORMA; a
                título meramente enunciativo, el software y sus códigos fuente y objeto, textos, gráficos, vídeos,
                imágenes, diseños, signos distintivos, logotipos, nombres comerciales, marcas, archivos de software,
                combinaciones de colores, así como la estructura, selección, ordenación y presentación del
                contenido de LA PLATAFORMA. Por lo tanto, siendo BEETESTED titular o cesionaria de los
                derechos de explotación de Propiedad Intelectual e Industrial sobre dichos elementos, queda
                prohibida su reproducción, distribución, comunicación pública y/o transformación a todos los
                Usuarios, en cualquier modalidad o formato, salvo que se indique lo contrario en LA PLATAFORMA.
                Asimismo, queda prohibida la reproducción, copia, cesión, total o parcial, de los elementos de LA
                PLATAFORMA, cualquiera que fuera su finalidad y el medio utilizado para ello, salvo que esté
                expresamente indicado por BEETESTED.
            </p><p className='paragraph'>
                Los signos distintivos, logotipos, nombres comerciales y/o marcas de otras empresas, que pudieran
                aparecer en LA PLATAFORMA durante la navegación del Usuario, son titularidad de cada una de
                ellas, por lo que el Usuario no podrá hacer uso de éstos sin autorización de dichos terceros.
            </p><p className='paragraph'>
                Queda autorizada la visualización y uso de los elementos anteriormente descritos, de los contenidos
                y de los productos/servicios de LA PLATAFORMA para el uso personal y los usos autorizados de LA
                PLATAFORMA, pero no para uso comercial ni para cualesquiera otros usos no autorizados.
            </p><p className='paragraph'>
                BEETESTED hará sus mayores esfuerzos para que los contenidos y los elementos de LA
                PLATAFORMA no infrinjan la Ley y/o no tengan carácter xenófobo, discriminatorio, pornográfico,
                racista y que no fomenten la violencia, tomando las medidas pertinentes desde el momento en el que
                tenga conocimiento efectivo de dichas infracciones.
            </p>
            <h2 className='title'>
                MISCÉLANEA
            </h2>
            <p className='paragraph'>
                Las condiciones del presente Aviso Legal constituyen el acuerdo total entre BEETESTED y el
                Usuario en lo referente al objeto del presente documento y solo se podrán modificar mediante una
                corrección escrita firmada por una persona autorizada de BEETESTED o a través de la publicación
                de una versión revisada por BEETESTED. Todo ello sin perjuicio de lo establecido en las Políticas de
                Privacidad y Cookies y las Condiciones de Uso de LA PLATAFORMA.
            </p><p className='paragraph'>
                Si cualquier cláusula de las condiciones de este Aviso Legal fuese declarada total o parcialmente
                nula o ineficaz afectará tan solo a esa disposición o parte de la misma que resulte nula o ineficaz,
                subsistiendo en todo lo demás el resto de las condiciones del Aviso Legal y teniéndose tal disposición
                o la parte de la misma que resulte afectada por no puesta salvo que, por resultar esencial al presente
                Aviso Legal, hubiese de afectarlos de manera integral.
            </p><p className='paragraph'>
                Los Usuarios estarán sujetos a las condiciones del Aviso Legal vigentes en cada momento y mientras
                utilicen LA PLATAFORMA, sin perjuicio de lo establecido en las Condiciones de Uso sobre la
                contratación de los productos y los servicios de BEETESTED y/o cuando la Ley o un organismo
                competente determinen otra cosa.
            </p><p className='paragraph'>
                LA PLATAFORMA y sus contenidos se ofrecen en lengua española e inglesa, por lo que, ante
                cualquier conflicto en la aplicación o interpretación de este Aviso Legal, las Políticas de Privacidad y
                Cookies y/o las Condiciones de Uso de BEETESTED, siempre prevalecerá la versión en lengua
                española sobre cualquier otra.
            </p>
            <h2 className='title'>
                LEY APLICABLE Y JURISDICCIÓN
            </h2>
            <p className='paragraph'>
                Este Aviso Legal, las Políticas de Privacidad y Cookies, las Condiciones de Compra y/o las
                Condiciones de Uso de BEETESTED, así como cualquier relación entre los Usuarios y BEETESTED,
                se regirán por la legislación española.
            </p><p className='paragraph'>
                En caso de conflicto en la aplicación o interpretación de dichos documentos, las partes se someten a
                los Juzgados y Tribunales de Madrid.
            </p><p className='paragraph'>
                Sin perjuicio de lo anterior, cuando el Usuario sea considerado como Consumidor podrá reclamar sus
                derechos como tal en relación con las Condiciones de Uso, el Aviso Legal y las Políticas de
                Privacidad y Cookies tanto ante los órganos jurisdiccionales en España en que esté domiciliado el
                Consumidor como ante los órganos jurisdiccionales en su Estado Miembro de residencia en la Unión
                Europea. Asimismo, en cumplimiento de lo establecido en el artículo 14.1 del Reglamento (UE)
                524/2013 del Parlamento Europeo y del Consejo de 21 de mayo de 2013, el Usuario queda
                informado de la existencia de una plataforma de resolución extrajudicial de litigios online puesta a
                disposición por la Comisión Europea, disponible en:&nbsp;
                <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage" target="_blank" rel="noreferrer">
                    https://ec.europa.eu/consumers/odr/
                </a> y a través de la cual los Consumidores podrán someter sus reclamaciones.
            </p>
        </div>
    );
}

export default LegalDisclaimer;
