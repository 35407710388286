const DemoViewTextInstructions = ({testInfo}) => {
    const downloadPassword = (
        testInfo.downloadPassword
        ? <span>(contraseña: <strong className='color-yellow'>{testInfo.downloadPassword}</strong>).</span>
        : null
    );

    return (
        <div className={'description-text'}>
            <p>Sigue las siguientes <strong>instrucciones</strong>:</p>
            <ol>
                <li>Descarga el juego de <strong>
                    <a 
                        className= 'summary-element-link' 
                        href={testInfo.download_link} 
                        target="_blank" 
                        rel='noreferrer'
                    >
                        este enlace
                    </a></strong>, y ejecuta el fichero <strong>.exe</strong>.&nbsp; 
                    {downloadPassword}
                </li>
                <li>
                    Empieza la grabación con el OBS, con la misma configuración de la escena y perfil,
                    tal como lo hiciste en el <a 
                        className= 'summary-element-link' 
                        href='https://forms.gle/UfgmYLDG93QsteUN9' 
                        target="_blank" 
                        rel='noreferrer'
                    >
                        test de prueba
                    </a>.
                </li>
                <li>
                    Sube el vídeo y contesta a las preguntas en <strong>
                    <a 
                        className= 'summary-element-link' 
                        href={testInfo.form_link} 
                        target="_blank" 
                        rel='noreferrer'
                    >
                        este formulario
                    </a></strong>
                </li>
                <li>
                    Te mandaremos el feedback al correo que nos proporcionaste.
                </li>
            </ol>
        </div>
    )
}

export default DemoViewTextInstructions;