import React, { useState } from 'react'
import './HelpText.css'
const HelpText = (props) => {
    props = {
        className:'',
        customIconClassName:'',
        customTextClassName: '',
        helpString:'',
        helpIcon: null,
        ...props
    }
    const [isHovering, setIsHovering] = useState(false);

    const handleMouseOver = () => {
      setIsHovering(true);
    };
  
    const handleMouseOut = () => {
      setIsHovering(false);
    };

    return (
        <div className={props.className}>
            <div
                className={props.customIconClassName + ' helper-icon'}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
            >
                {props.helpIcon}
            </div>
            
            { isHovering && 
                (
                    <div 
                        className={props.customTextClassName + ' helper-text'}
                        onMouseOver={handleMouseOver}
                        onMouseOut={handleMouseOut}
                    >
                        {props.helpString}
                    </div>
                )
            }
        
        </div>
    )
    

}

export default HelpText;