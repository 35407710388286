import axios from 'axios';
import { API_URL } from '../constants';

const TestingService = () => {
    return {
        async showTest(token, testId) {
            const response = await axios.get(
                API_URL + '/tests/show/' + testId,
                { headers: token }
            );
            return response;
        },
        async startTestSession(token, testId) {
            const response = await axios.post(
                API_URL + '/tests/session',
                { test_id: testId },
                { headers: token }
            )
            return response
        },
        async submitVideo(token, sessionId) {
            const response = await axios.post(
                API_URL + '/tests/create',
                {
                    user_test_id: sessionId
                },
                {headers: token}
            )
            return response
        },
        async submitSurvey(token, surveyData) {
            const response = await axios.post(
                API_URL + '/tests/survey',
                surveyData,
                {headers: token}
            )
            return response
        },
        async finishTestSession(token, sessionId) {
            const response = await axios.put(
                API_URL + '/tests/session-finish',
                {id: sessionId},
                {headers: token},
            )
            return response
        }
    };
}

export default TestingService;
