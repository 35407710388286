import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../services/auth.service';
import { Link } from 'react-router-dom';
import Reaptcha from "reaptcha";
import { DRF_RECAPTCHA_PUBLIC_KEY } from '../../constants';
import ErrorsComponent from '../ErrorsComponent/ErrorsComponent';


const Login = () => {
    const { isAuthed, login } = useContext(AuthContext);
    const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [recaptchaValue, setRecaptchaValue] = useState(null);

    const handleCaptchaChange = (value) => {
        setRecaptchaValue(value);
    }
    useEffect(() => {
        if (isAuthed) {
            navigate('/home');
        }
    }, [isAuthed, navigate])

    const handleLogin = (e) => {
        e.preventDefault();
        const recaptcha = recaptchaValue
        let newErrors = {};
        if(recaptcha == null){
            newErrors['captcha'] = 'Por favor confirma que no eres un robot';
            setErrors(newErrors);
        }
        document.body.classList.add('loading');
        const errorLabel = document.getElementById('error-label');
        errorLabel.classList.add('hidden');

        if (username !== '' && password.length >= 8) {
            login(username, password)
            .then((res) => {
                document.body.classList.remove('loading');
                navigate('/home');
            })
            .catch((err) => {
                document.body.classList.remove('loading');
                errorLabel.classList.remove('hidden');
            });
        } else {
            document.body.classList.remove('loading');
            setTimeout(() => errorLabel.classList.remove('hidden'), 300);
        }
    }

    return(
        <div className="form-container">
            <h1>Iniciar sesión</h1>
            <div className="login-form">
                <form onSubmit={handleLogin}>
                    <input
                        type="text"
                        className="input username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="nickname o email"
                    />
                    <input
                        type="password"
                        className="input password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="contraseña"
                    />
                
                    <Reaptcha 
                        className='container-g-recaptcha'
                        sitekey={DRF_RECAPTCHA_PUBLIC_KEY}
                        onVerify={handleCaptchaChange}
                        theme='light'
                    />

                    <div className='errors-container'>
                        <ErrorsComponent errors={errors}/>
                    </div>
                    
                    <button
                        className="submit-button"
                        type="submit"
                    >Enviar</button>

                    <div className='switch-login'>
                        <div
                            id='error-label'
                            className='error-text text-center hidden'
                        >Usuario o contraseña incorrectos</div>
                        <Link 
                            className='switch-login-link'
                            to='/login/forgot-password'
                        >¿Has olvidado tu contraseña?</Link>
                    </div>
                </form>
                <div className='switch-login'>
                    ¿No tienes cuenta?
                    <Link 
                        className='switch-login-link'
                        to='/register'
                    >Regístrate</Link>
                </div>
            </div>
        </div>
    )
};


export default Login;
