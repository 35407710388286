import { useEffect, useState } from "react";
import { API_URL } from "../../constants";
import axios from "axios";
import ProfilePreviewField from "../ProfilePreviewField/ProfilePreviewField";
import authHeader from "../../services/auth-header";
import UserValidator from "../../utils/user.validator";

const ProfilePreview = ({user, setUser}) =>{
    const [isEditing, setIsEditing] = useState(false);
    const [error, setError] = useState('');
    const [countryOptions, setCountryOptions] = useState([]);

    useEffect(() => {
        document.body.classList.add('loading');

        axios.get(
            API_URL + '/core/countries'
        ).then((res) => {
            const options = [{value: '', text: '-'}];
            if (Array.isArray(res.data)) {
                res.data.forEach((country) => {
                    options.push(
                        {value: country.iso, text: country.name_es}
                    )
                });
            }
            setCountryOptions(options);
        });

    }, []);
    
    const {
        validateUsername,
        validateEmail,
    } = UserValidator();


    const toggleIsEditing = () => {
        setIsEditing(!isEditing);
    };

    const reloadUser = () => {
        document.body.classList.add('loading');
        setError('');
        authHeader().then((token) => {
            axios.get(
                API_URL + '/auth/users/profile',
                {
                    headers: token
                }
            ).then((res) => {
                setUser({...user, ...res.data});
                document.body.classList.remove('loading');
                // Remove all errors
                const errorLabels = document.getElementsByClassName('error-label');

                for (let i = 0; i < errorLabels.length; i++) {
                    const errorLabel = errorLabels.item(i);
                    errorLabel.innerHTML = '';
                    errorLabel.classList.add('hidden');
                }
                toggleIsEditing();
            }).catch((err) => {
                console.log(err);
                document.body.classList.remove('loading');
                // TODO deal with error
            });
        });
    }

    const postProfile = () => {
        document.body.classList.add('loading');
        if (!validateEmail(user.email).isValid || !validateUsername(user.username).isValid) {
            document.body.classList.remove('loading');
            return;
        }
        
        authHeader().then((token) => {
            axios.post(
                API_URL + '/auth/users/profile',
                user,
                {
                    headers: token
                }
            ).then((res) => {
                setError('');
                toggleIsEditing();
                document.body.classList.remove('loading');
            }).catch((err) => {
                let error = err.response.data.error;
                error = error.split(':').map(str => str.trim());
                error = error[error.length - 1]

                if (!error) {
                    error = 'Ha ocurrido un error: ' + err.response.status;
                }
                setError(error);
                document.body.classList.remove('loading');
                // TODO deal with error
            });
        });
    }

    return (
        <div className='user-profile'>
            <div className={'error-message' + (error ? '' : ' hidden')}>
                {error}
            </div>
            <div className='user-profile-fields'>
                <ProfilePreviewField
                    type='text'
                    name='username'
                    value={user.username}
                    label='username'
                    onChange={(e) => setUser({...user, username: e.target.value})}
                    isEditing={isEditing}
                    validator={validateUsername}
                />
                <ProfilePreviewField 
                    type='text'
                    name='email'
                    value={user.email}
                    label='email'
                    onChange={(e) => setUser({...user, email: e.target.value})}
                    isEditing={isEditing}
                    validator={validateEmail}
                />
                <ProfilePreviewField 
                    type='date'
                    name='birth_date'
                    min='1920-01-01'
                    max='2005-01-01'
                    value={user.birth_date ? user.birth_date : '-'}
                    label='Fecha de nacimiento'
                    onChange={(e) => setUser({...user, birth_date: e.target.value})}
                    isEditing={isEditing}
                />
                <ProfilePreviewField
                    type='select'
                    name='country'
                    value={user.country ? user.country : ''}
                    label='Nacionalidad'
                    onChange={(e) => setUser({...user, country: e.target.value})}
                    isEditing={isEditing}
                    options={countryOptions}
                />
                <ProfilePreviewField
                    type='select'
                    name='gender'
                    value={user.gender != null ? user.gender : ''}
                    label='Género'
                    onChange={(e) => setUser({...user, gender: e.target.value ? parseInt(e.target.value) : null})}
                    isEditing={isEditing}
                    options={[
                        {value: '', text: '-'},
                        {value: 0, text: 'Hombre'},
                        {value: 1, text: 'Mujer'},
                        {value: 2, text: 'Otro / Prefiero no decirlo'},
                    ]}
                />
            </div>
            <div className="edit-button">
                <button 
                    onClick={reloadUser}
                    className={'cancel' + (!isEditing ? ' hidden' : '')}
                >
                    Cancelar
                </button>
                
                <button 
                    onClick={postProfile}
                    className={'save' + (!isEditing ? ' hidden' : '')}
                >
                    Guardar
                </button>

                <button 
                    onClick={toggleIsEditing}
                    className={'edit' + (isEditing ? ' hidden' : '')}
                >
                    Editar perfil
                </button>
            </div>
            
        </div>
    )

}

export default ProfilePreview;