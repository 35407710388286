import './Wallet.css';
import HelpText from '../HelpText/HelpText';


const Wallet = (props) => {
    props = {
        wallet: {
            xp: 0,
            honeycoins: 0,
        },
        ...props
    }

    return (
        <>
            <div className='wallet-component'>
                <div className={props.walletValuesClassName}>
                    {props.wallet.xp}
                </div>
                <div className={props.walletLabelsClassName}>
                    XP
                </div>
                    <HelpText 
                        customTextClassName = 'help-text-wallet-rigth'
                        helpString= {'Esta es la experiencia que has ganado en Beeteested.' +
                    	' Con ella te permitiremos realizar nuevas funciones dentro de la plataforma.' 
                        } 
                        helpIcon = '?'
                    />
            </div>
            <div className='wallet-component'>
                <div className={props.walletValuesClassName}>
                    {props.wallet.honeycoins}
                </div>
                <div className={props.walletLabelsClassName}>
                    honeycoins
                </div>
                <HelpText 
                    customTextClassName = 'help-text-wallet-left'
                    helpString= {'Este es el crédito que has ganado en Beetested.' +
                    ' Muy pronto podrás gastarlo y comprar recompensas en nuestra tienda.'}
                    helpIcon = '?'
                />
            </div>
        </>
    );

}

export default Wallet;