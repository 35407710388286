import '../../styles/Legal.css'

const Cookies = () => {
    return (
        <div className='legal-text-container'>
            <h1 className='title'>
                POLÍTICA DE COOKIES
            </h1>
            <h2 className='title'>
                ¿Qué son las cookies?
            </h2>
            <p className='paragraph'>
                LA PLATAFORMA utiliza cookies y/o tecnologías similares que almacenan y
                recuperan información cuando navegas. En general, estas tecnologías pueden servir
                para finalidades muy diversas, como, por ejemplo, información sobre tu navegador,
                el Sistema Operativo que utilizas, tu dirección IP o tu ubicación, entre otras. Los
                usos concretos que hacemos de estas tecnologías se describen a continuación.
            </p>
            <h2 className='title'>
                Identificación sobre el tipo de cookies que utilizamos y su finalidad
            </h2>
            <table className='legal-table'>
                <thead>
                <tr>
                    <th>
                        Nombre
                    </th>
                    <th>
                        Proveedor
                    </th>
                    <th>
                        Finalidad
                    </th>
                    <th>
                        Tipo
                    </th>
                    <th>
                        Duración
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>A</td>
                    <td>B</td>
                    <td>C</td>
                    <td>D</td>
                    <td>E</td>
                </tr>
                </tbody>
            </table>
            <h2 className='title'>
                Información sobre la forma de aceptar, denegar o revocar el
                consentimiento para el uso de cookies
            </h2>
            <p className='paragraph'>
                El uso de cookies puede tener un efecto sobre la privacidad de los usuarios dado
                que, en algunos casos, permite relacionar su contenido con la dirección IP de
                conexión y con otros datos de carácter personal del usuario, como los aportados
                voluntariamente.
            </p><p className='paragraph'>
                Mediante la aceptación de la presente Política de Cookies o de la instalación de
                cookies a través del banner, el usuario estará consintiendo la generación de cookies
                para las finalidades arriba mencionadas.
            </p><p className='paragraph'>
                No obstante lo anterior, en caso de que el usuario desee con posterioridad eliminar
                las cookies que se hayan almacenado en su ordenador y que necesariamente
                precisen de su consentimiento, podrá hacerlo usando las herramientas que le
                proporcione su navegador a dichos efectos. En este sentido, dependiendo de su
                navegador, en los siguientes enlaces se detalla el procedimiento para desactivar las
                cookies:
            </p>
            <ul>
                <li>
                    <a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we&redirectlocale=es" target="_blank" rel="noreferrer">Firefox</a>
                </li>
                <li>
                    <a href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d" target="_blank" rel="noreferrer">Internet Explorer</a>
                </li>
                <li>
                    <a href="https://support.google.com/chrome/answer/95647?hl=es" target="_blank" rel="noreferrer">Chrome</a>
                </li>
                <li>
                    <a href="https://support.apple.com/es-es/HT201265" target="_blank" rel="noreferrer">Safari</a>
                </li>
                <li>
                    <a href="https://help.opera.com/en/latest/web-preferences/#cookies" target="_blank" rel="noreferrer">Opera</a>
                </li>
            </ul>
            <p className='paragraph'>
                Si el usuario no acepta las cookies o las rechaza posteriormente, las
                funcionalidades de LA PLATAFORMA podrán verse mermadas.
            </p>
        </div>
    );
};

export default Cookies;
