import axios from 'axios';
import { API_URL } from '../constants';
const CoreServices = () => {
    return {
        async getVideoGamesListFiltered(token, titleQuery) {
            const response = await axios.get(
                API_URL + '/core/videogames/' + titleQuery, 
                { headers: token },
            )
            return response;
        },
        async postVideoGamesListFiltered(token, videogameIDs) {
            const response = await axios.post(
                API_URL + '/core/user-game-create',
                { videogame_ids: videogameIDs },
                { headers: token }
            )
            return response;
        },
    };
}

export default CoreServices;