import '../../styles/Legal.css';

const UseConditions = () => {

    return (
        <div className='legal-text-container'>
            <h1 className='title'>
                CONDICIONES DE USO BEETESTED
            </h1>
            <p className='paragraph'>
                Las presentes Condiciones Generales de Contratación (en adelante, las Condiciones de Uso)
                regulan las relaciones entre los usuarios de internet (en adelante, el Usuario o los Usuarios) y
                BEETESTED, S.L., con domicilio social en Calle Mariquez 21, 6-8, 28009 - Madrid, con email
                de contacto <a href="mailto:hello@beetested.com">hello@beetested.com</a> y con CIF 72511413,
                que figura inscrita en el Registro Mercantil de Madrid, al Tomo 44018, folio 101, hoja M-776448,
                inscripción primera (en adelante, BEETESTED), en lo que se refiere a la creación de un Perfil de Usuario
                y al acceso del área privada de la Plataforma de BEETESTED (en adelante, LA PLATAFORMA).
            </p>
            <p className='paragraph'>
                Las presentes Condiciones de Uso serán de aplicación de forma conjunta con el <a href='/legal/disclaimer'>Aviso Legal,</a> la&nbsp;
                <a href='/legal/policies/privacy'>Política de Privacidad</a> y la <a href='/legal/policies/cookies'>Política de Cookies</a>
                &nbsp;y cualquier otro documento que regule aspectos de LA PLATAFORMA. Las Condiciones de Uso que aquí se recogen
                son aplicables a todas las formas de acceso que se hagan a LA PLATAFORMA, incluyendo Internet, vía móvil o cualquier
                otro dispositivo, cuando fuese posible.
            </p>
            <h2 className='title'>
                FORMALIZACIÓN Y CONSENTIMIENTO
            </h2>
            <p className='paragraph'>
                El registro por parte de los Usuarios en LA PLATAFORMA es gratuito y está sujeto a la previa
                aceptación de forma expresa de todas las Condiciones de Uso vigentes en cada momento. Si
                algún Usuario no estuviese de acuerdo con el contenido o parte del contenido de las presentes
                Condiciones de Uso no podrá registrarse en LA PLATAFORMA, ni podrá acceder ni disponer
                de los contenidos y servicios que en ésta se ofrecen. Se recomienda que el Usuario lea
                atentamente las presentes Condiciones de Uso cada vez que acceda a LA PLATAFORMA, ya
                que podrían sufrir modificaciones.
            </p><p className='paragraph'>
                El registro y la creación de un Perfil de Usuario en LA PLATAFORMA constituye la
                formalización de un contrato con BEETESTED para el uso de LA PLATAFORMA y conlleva la
                aceptación de la Política de Privacidad para el uso de su imagen y sus datos personales con
                las finalidades indicadas en LA PLATAFORMA.
            </p><p className='paragraph'>
                Cuando los Usuarios realizan el registro en LA PLATAFORMA recibirán un e-mail de
                confirmación, entendiéndose formalizada la relación contractual en el momento en el que los
                Usuarios reciban dicho e-mail.
            </p><p className='paragraph'>
                El Usuario queda informado y acepta expresamente que los datos personales facilitados serán
                incorporados a un fichero automatizado, cuya titularidad como Responsable del tratamiento
                corresponde a BEETESTED, así como que dichos datos podrán ser facilitados a otros terceros
                colaboradores y proveedores para dar cumplimiento a la prestación de los servicios ofrecidos
                en LA PLATAFORMA.
            </p><p className='paragraph'>
                El Usuario declara ser mayor de edad y disponer de la capacidad jurídica suficiente para
                vincularse por las Condiciones de Uso. Igualmente, acepta de forma expresa y sin excepciones
                que la utilización de LA PLATAFORMA y/o de los contenidos de LA PLATAFORMA tiene lugar
                bajo su única y exclusiva responsabilidad.
            </p><p className='paragraph'>
                BEETESTED se reserva el derecho a denegar en cualquier momento, sin necesidad de aviso
                previo y sin derecho a indemnización, el acceso a LA PLATAFORMA a aquellos Usuarios que
                incumplan las presentes Condiciones de Uso o cualquier otra de sus Políticas, pudiendo
                eliminar el Perfil del Usuario infractor.
            </p><p className='paragraph'>
                BEETESTED se reserva el derecho de efectuar sin previo aviso los cambios y las
                modificaciones que considere oportunas en LA PLATAFORMA y en las presentes Condiciones
                de Uso, intentando ponerlo previamente en conocimiento de los Usuarios, siempre que las
                circunstancias así se lo permitan.
            </p>
            <h2 className='title'>
                REGISTRO
            </h2>
            <p className='paragraph'>
                Cuando el Usuario se registra cumplimentando el Formulario de Registro reconoce haber
                tenido conocimiento del contenido de las presentes Condiciones de Uso y declara
                expresamente que las acepta.
            </p><p className='paragraph'>
                Los Usuarios serán responsables de la adecuada custodia y confidencialidad de cualquier
                contraseña y se comprometen a no ceder su uso a terceros, ni a permitir su acceso a personas
                ajenas. Será responsabilidad de los Usuarios la utilización ilícita de LA PLATAFORMA por
                cualquier tercero ilegítimo que emplee una contraseña a causa de una utilización no diligente o
                de la pérdida de la misma por el Usuario.
            </p><p className='paragraph'>
                El Usuario tiene la obligación de notificar de forma inmediata a BEETESTED cualquier hecho
                que conlleve el uso indebido de las contraseñas, tales como el extravío o el acceso no
                autorizado con el fin de proceder a su inmediata cancelación. Mientras no se comuniquen tales
                hechos, BEETESTED estará eximido de cualquier responsabilidad que pudiera derivarse de
                ese uso indebido de las contraseñas por terceros no autorizados.
            </p>
            <h2 className='title'>
                PERFIL DE USUARIO Y CONDICIÓN DE TESTER
            </h2>
            <p className='paragraph'>
                Una vez creado el Perfil de Usuario, los Usuarios adquieren la condición de Tester de los
                Videojuegos de los Clientes de BEETESTED disponibles en LA PLATAFORMA, accediendo a
                que BEETESTED procese su imagen personal, desde el momento en el que suban vídeos a LA
                PLATAFORMA realizando el testeo de los Videojuegos.
            </p><p className='paragraph'>
                Por lo tanto, los Usuarios conceden una autorización de derechos de imagen, gratuita u
                onerosa (en función de las condiciones de cada testeo), en todo el mundo, sobre los videos en
                los que apareciese su imagen, voz, nombre y acciones para su análisis y el uso de su imagen
                por parte de la inteligencia artificial de BEETESTED, que analiza las emociones de la
                experiencia de los Usuarios en tiempo real y cruzándolas con lo que está pasando en el
                Videojuego. Por lo tanto, BEETESTED será titular en exclusiva, en todo el mundo y hasta el
                paso al dominio público de los vídeos, imágenes y el material audiovisual y fotográfico
                generados en los testeos de LA PLATAFORMA en los que pareciesen la imagen, voz, nombre
                y acciones de los Usuarios.
            </p><p className='paragraph'>
                BEETESTED podrá utilizar los videos y las imágenes que contengan la imagen del Usuario
                para reentrenar el modelo de su inteligencia artificial hasta que el Usuario ejerza su derecho de
                cancelación u oposición.
            </p><p className='paragraph'>
                Los Usuarios entienden y aceptan que el testeo y el acceso a los Videojuegos disponibles en
                LA PLATAFORMA tendrán la condición de confidenciales y se les aplicará la Obligación de
                Confidencialidad establecida en las presentes Condiciones de Uso, salvo que BEETESTED
                indicase lo contrario en las condiciones del testeo de cada Videojuego de forma expresa.
            </p><p className='paragraph'>
                Asimismo, los Usuarios entienden y aceptan que el testeo y el acceso a algunos de los
                Videojuegos podría conllevar la condición adicional de firmar un Acuerdo de Confidencialidad
                con BEETESTED y/o con los Clientes de BEETESTED titulares de los Videojuegos, cuestión
                que se indicará en las condiciones del testeo del Videojuego de forma expresa cuando
                corresponda.
            </p>
            <h3 className='title title-2'>
                CONDICIONES DE LOS VÍDEOS
            </h3>
            <p className='paragraph'>
                BEETESTED se reserva el derecho de marcar un vídeo subido por el Usuario como “no válido”.
                En ese caso, el testeo se considerará no completado y no tendrá derecho a recompensa
                cuando esta sea aplicable.
            </p><p className='paragraph'>
                Se considera un archivo de vídeo válido aquel que:
            </p>
            <ul>
                <li>Ha sido grabado con la herramienta de grabación de LA PLATAFORMA de BEETESTED.</li>
                <li>Muestra correctamente y en todo momento la cara completa del Usuario.</li>
                <li>Muestra correctamente el Videojuego que se está testeando.</li>
                <li>Tiene una duración igual o superior a la duración mínima indicada por BEETESTED.</li>
                <li>Están completados los datos de experiencia sobre este tipo de Videojuego y la valoración del mismo.</li>
            </ul>
            <h3 className='title title-2'>
                RECOMPENSAS DE LOS USUARIOS
            </h3>
            <p className='paragraph'>
                Los Usuarios recibirán recompensas que pueden variar en función de cada testeo que realicen
                de los Videojuegos disponibles en LA PLATAFORMA y que podrán consultar antes de iniciar
                dicho testeo, comprometiéndose a cumplir con las condiciones del testeo indicadas por
                BEETESTED. Estas recompensas serán definidas por BEETESTED y el Usuario, en cada
                caso, acepta que dicha recompensa es la contraprestación a la que tiene derecho por el testeo
                del Videojuego en cada momento y por la autorización de sus derechos de imagen, sin que
                pueda reclamar nada más a BEETESTED.
            </p><p className='paragraph'>
                Los Usuarios pueden optar a dos tipos de recompensas:
            </p>
            <ul>
                <li>
                <strong>XP:</strong> Con cada actividad que los Usuarios realicen en LA PLATAFORMA (probar un
                videojuego, contestar una encuesta, etc.) ganarán experiencia que servirá para subir
                de nivel entre los otros Testers y permitirá el desbloqueo de nuevos beneficios
                disponibles en LA PLAFORMA. Los Usuarios podrán consultar antes de su desbloqueo
                y activación las condiciones de dichos beneficios.
                </li>
                <li>
                <strong>HONEYCOINS:</strong> Es la moneda oficial de BEETESTED. Por norma general, 100
                honeycoins equivalen a 1 euro. Podrás usar tus honeycoins para obtener tarjetas de
                regalo (Steam, Amazon y/o otros terceros indicados en su caso en LA PLATAFORMA)
                o donar dinero en Kickstarter (u otras plataformas de crowdfunding indicadas en cada momento)
                a un Videojuego que esté buscando financiación.
                Además, en determinados casos, los Testers con más XP podrán incluso canjear sus
                honeycoins por dinero en metálico, siempre y cuando cumplan los requisitos
                establecidos por BEETESTED en cada momento y que los Usuarios podrán consultar.
                </li>
            </ul>
            <p className='paragraph'>
                No obstante, en LA PLATAFORMA podrá haber testeos de Videojuegos que no tengan
                recompensa para los Usuarios; por lo tanto, en dichos casos, cuando el Usuario decida
                participar en dicho testeo, el Usuario entiende y acepta que la autorización de sus derechos de
                imagen y su participación en el testeo tienen carácter gratuito, sin que pueda reclamar nada
                más a BEETESTED.
            </p>
            <h2 className='title'>
                LICENCIA DE USO
            </h2>
            <p className='paragraph'>
                BEETESTED concede una licencia gratuita de uso personal, no transferible, temporal (mientras
                el Usuario utilice LA PLATAFORMA) y no exclusiva sobre LA PLATAFORMA y los programas
                de ordenador disponibles para que el Usuario acceda a LA PLATAFORMA en su ordenador,
                sobre la versión disponible en cada momento para la utilización de LA PLATAFORMA.
            </p><p className='paragraph'>
                Por lo tanto, el Usuario no podrá realizar la reproducción total o parcial, la distribución pública o
                la traducción, adaptación, arreglo ni cualquier otra transformación de LA PLATAFORMA y los
                programas de ordenador disponibles (incluida la corrección de errores), ni parcial ni total, ni la
                realización de versiones sucesivas de los programas licenciados sin autorización previa de
                BEETESTED, pudiendo utilizarla y acceder según los usos y/o utilizaciones que estén
                permitidos expresamente por BEETESTED. Por ello, el Usuario adquiere solamente el derecho
                a usar y acceder a LA PLATAFORMA en el equipo de su propiedad y en ningún caso esta
                licencia implica la cesión de derechos de Propiedad Intelectual sobre LA PLATAFORMA y/o los
                programas de ordenador disponibles, por lo que el Usuario no podrá proceder a su
                redistribución, venta, alquiler, préstamo y/o cualquier otra cesión de uso a terceros, ni podrá
                realizar alteración, manipulación, desmontaje, descompilación y/o la modificación de LA
                PLATAFORMA mediante ingeniería inversa, o por cualquier otro procedimiento, sin la
                autorización expresa y por escrito de BEETESTED.
            </p><p className='paragraph'>
                BEETESTED es titular de todos los derechos de Propiedad Intelectual e Industrial de LA
                PLATAFORMA (incluyendo los manuales de uso, sus especificaciones técnicas, inteligencia
                artificial, algoritmos, código fuente y código objeto) que continuarán siendo propiedad de
                BEETESTED.
            </p><p className='paragraph'>
                Sin perjuicio de lo establecido en las presentes Condiciones de Uso y cuando fuese aplicable,
                BEETESTED responderá de las faltas de conformidad o de los defectos en LA PLATAFORMA
                dentro del plazo de dos (2) años siguientes a la fecha en que el Usuario comenzase a utilizar
                efectivamente LA PLATAFORMA.
            </p><p className='paragraph'>
                BEETESTED proporcionará las actualizaciones y correcciones de la versión disponible en cada
                momento de LA PLATAFORMA para el normal funcionamiento de LA PLATAFORMA, pero
                BEETESTED no estará obligado a proporcionar actualizaciones ni mantenimiento para LA
                PLATAFORMA no necesarios para el funcionamiento de LA PLATAFORMA. En el caso de que
                BEETESTED, a su entera discreción, proporcionara actualizaciones de LA PLATAFORMA, el
                Usuario acepta que BEETESTED puede poner fin a dichas actualizaciones en cualquier
                momento y por cualquier razón.
            </p>
            <h2 className='title'>
                DERECHO DE DESISTIMIENTO
            </h2>
            <p className='paragraph'>
                Una vez aceptadas las presentes Condiciones de Uso, los Usuarios entienden y aceptan que
                desde el momento en el que se registran y utilizan LA PLATAFORMA no existirá derecho de
                desistimiento en virtud de lo establecido en el artículo 103, letra m), del Real Decreto
                Legislativo 1/2007, de 16 de noviembre, por el que se aprueba el texto refundido de la Ley
                General para la Defensa de los Consumidores y Usuarios, al tratarse del suministro de
                contenido digital que no se presta en un soporte material.
            </p>
            <h2 className='title'>
                PROMOCIONES, CONCURSOS Y OFERTAS
            </h2>
            <p className='paragraph'>
                BEETESTED podrá realizar promociones, ofertas y/o concursos a los que se les aplicarán sus
                propias bases, condiciones y términos adicionales, que BEETESTED pondrá a disposición de
                los Usuarios antes de que se inscriban en dichas promociones, ofertas y/o concursos. En caso
                de que un Usuario quiera participar en una promoción, oferta o concurso, deberá leer y estar
                conforme con sus bases, condiciones y términos.
            </p><p className='paragraph'>
                Asimismo, y cuando fuese aplicable, las promociones, concursos y ofertas de LA
                PLATAFORMA tendrán sus propios términos económicos que estarán a disposición de los
                Usuarios antes de que se contraten. Los concursos, ofertas y promociones disponibles para los
                Usuarios serán válidos hasta la fecha indicada para los mismos y, en su caso, de forma
                individualizada sobre los productos/contenidos indicados, sin que sean aplicables a otros
                productos o servicios ofrecidos en LA PLATAFORMA.
            </p>
            <h2 className='title'>
                GARANTÍAS Y OBLIGACIONES DE LOS USUARIOS
            </h2>
            <p className='paragraph'>
                Adicionalmente a las obligaciones y garantías aceptadas por los Usuarios en el Aviso Legal de
                LA PLATAFORMA, los Usuarios garantizan que no utilizarán LA PLATAFORMA y/o los
                contenidos disponibles con fines comerciales en ningún caso y sin la autorización de
                BEETESTED.
            </p><p className='paragraph'>
                Los Usuarios garantizan y quedan obligados a que toda la información de su Perfil de Usuario
                es veraz, cierta y completa, por lo que serán responsables frente a terceros de la información
                que proporcionen.
            </p><p className='paragraph'>
                Los Usuarios entienden y aceptan que BEETESTED es titular o cesionario de todos los
                derechos de Propiedad Intelectual e Industrial sobre los elementos y el contenido de LA
                PLATAFORMA. Por ello, todos los contenidos, imágenes, fotografías, videojuegos, diseños,
                marcas, rótulos, signos distintivos y/o logos de BEETESTED y/o de LA PLATAFORMA,
                banners, el software y sus distintos códigos, fuente y objeto, algoritmos, medidas tecnológicas
                y/o cualesquiera otros elementos de naturaleza análoga y/o propios de LA PLATAFORMA son
                titularidad de BEETESTED o BEETESTED es cesionario de los mismos.
            </p><p className='paragraph'>
                Los Usuarios garantizan que no cometerán actos y/o que no realizarán acciones dirigidas a
                romper las medidas tecnológicas de protección y antipiratería que hubiesen sido implantadas
                en LA PLATAFORMA, entendiéndose por tales aquellos procedimientos, técnicas, dispositivos,
                componentes, o la combinación de éstos, cuya función es controlar, impedir o restringir el
                acceso o la utilización de los elementos y/o el contenido de LA PLATAFORMA. De lo contrario,
                BEETESTED procederá a resolver de inmediato la relación con el Usuario infractor, así como
                que podrá tomar las medidas legales pertinentes.
            </p><p className='paragraph'>
                Asimismo, todos los Usuarios garantizan expresamente que no infringirán derechos de
                porpiedad Intelectual o Industrial de terceros, los derechos de terceros de ninguna naturaleza,
                ni infringirán el derecho al honor, la intimidad o la propia imagen de BEETESTED, así como de
                cualquier otra persona o de terceros y que son los únicos responsables, con completa
                indemnidad a BEETESTED, de cualquier reclamación (judicial o extrajudicial) que surja o
                pudiera surgir en tal supuesto.
            </p><p className='paragraph'>
                Los Usuarios garantizan y quedan obligados a cumplir estas Condiciones de Uso, el Aviso
                Legal y la Política de Privacidad y de Cookies de LA PLATAFORMA, respetando el interés
                público, la legalidad vigente y las exigencias de la buena fe.
            </p>
            <h2 className='title'>
                OBLIGACIÓN DE CONFIDENCIALIDAD
            </h2>
            <p className='paragraph'>
                Durante la vigencia de su condición de Usuario de LA PLATAFORMA y durante un período de
                diez (10) años tras su finalización, los Usuarios se comprometen a guardar confidencialidad
                respecto a todos y cada uno de los datos e informaciones de LA PLATAFORMA,
                especialmente aquellos relativos a las funcionalidades de LA PLATAFORMA y a los
                Videojuegos disponibles en esta (tales como, sin carácter exhaustivo, coste de los servicios,
                look and feel de LA PLATAFORMA, empresas implicadas, trabajadores, proveedores,
                personajes e historia de los Videojuegos, la temática de los Videojuegos, el código fuente,
                código objeto, algoritmos, inteligencia artificial o cualquier otro elemento, información o
                circunstancia relacionados con LA PLATAFORMA y los Videojuegos). Los Usuarios entienden
                y aceptan que las obligaciones de confidencialidad aquí previstas permanecerán en vigor
                durante toda la vigencia de la relación entre BEETESTED y los Usuarios así, como una vez
                extinguida esta de forma indefinida en lo que se refiere a información confidencial que esté
                protegida por la legislación en materia de Propiedad Intelectual, Industrial, Competencia
                Desleal, Secreto Empresarial y/o cualesquiera otros derechos relativos a cualquier información
                o conocimiento (tecnológico, científico, industrial, comercial, organizativo o financiero) de los
                que sea titular BEETESTED o que pertenezcan a los Clientes de BEETESTED titulares de los
                Videojuegos disponibles y que supongan o puedan ser calificados legalmente como un
                beneficio empresarial o ventaja competitiva/empresarial de BEETESTED y/o de los Clientes de
                BEETESTED titulares de los Videojuegos disponibles en LA PLATAFORMA. Asimismo, si
                fuese aplicable, los Usuarios se comprometen a devolver o destruir cualquier archivo,
                documento y/o cualquier otro soporte que incluya información confidencial de BEETESTED
                cuando finalice la relación entre las Partes.
            </p><p className='paragraph'>
                La obligación de confidencialidad aquí expresada se extiende a cualquier tercero, profesional o
                cualesquiera otras personas físicas y jurídicas que directa o indirectamente entren en contacto
                con los Usuarios en relación con LA PLATAFORMA y que tengan conocimiento o acceso a
                esta información. El incumplimiento de la obligación de confidencialidad podrá suponer la
                resolución de la relación con el Usuario por parte de BEETESTED, sin perjuicio del derecho de
                BEETESTED y/o de los Clientes de BEETESTED titulares de los Videojuegos disponibles en
                LA PLATAFORMA a ejercitar las acciones judiciales, que en su caso correspondan y las que
                procedan por daños y perjuicios. Por lo tanto, el Usuario entiende y acepta expresamente que,
                en caso de incumplimiento del deber de confidencialidad, BEETESTED podrá ceder sus datos
                personales a sus Clientes con el fin de exigir las responsabilidades que correspondan, sobre la
                base del interés legítimo del Cliente (Art. 6.1. f) del Reglamento General de Protección de
                Datos (interés legítimo de tercero) para proteger la confidencialidad y el Secreto Empresarial
                relacionado con sus actividades y con el Videojuego.
            </p><p className='paragraph'>
                Los Usuarios podrán revelar la información sobre los Videojuegos disponibles en LA
                PLATAFORMA y su participación en los testeos cuando BEETESTED lo indicase
                expresamente en cada caso concreto y/o (i) cuando la Información Confidencial se encontrase
                en el dominio público en el momento de acceder a LA PLATAFORMA (ii) cuando dicha
                información haya sido lícitamente puesta en el dominio público por una persona autorizada
                para ello; o (iii) cuando la legislación vigente o un mandato judicial exija su divulgación.
            </p><p className='paragraph'>
                Sin perjuicio de lo anterior, en ciertos casos (cuando así se indique en las condiciones del
                testeo de cada Videojuego) los Usuarios tendrán que firmar un Acuerdo de Confidencialidad
                adicional con BEETESTED y/o con los Clientes de BEETESTED titulares de los Videojuegos
                antes de poder participar en el testeo y para poder acceder a la información relativa al
                Videojuego en cuestión.
            </p>
            
            <h2 className='title'>
                RESPONSABILIDAD Y GARANTÍAS DE BEETESTED
            </h2>
            <p className='paragraph'>
                BEETESTED no será responsable de aquellas infracciones o incumplimientos de los Usuarios,
                ni de aquellas obligaciones asumidas y/o derechos que hubiesen adquirido los Usuarios por la
                relación establecida con terceros, pues serán los propios Usuarios los responsables del
                cumplimiento de los compromisos asumidos y adquiridos en cada momento.
            </p><p className='paragraph'>
                BEETESTED no tiene obligación y no controla la utilización que los Usuarios hacen de LA
                PLATAFORMA, de los Videojuegos, los contenidos y/o de los servicios disponibles en LA
                PLATAFORMA. Así, BEETESTED no garantiza que los Usuarios utilicen LA PLATAFORMA,
                los contenidos, los Videojuegos y los servicios ofrecidos de conformidad con estas Condiciones
                de Uso, ni que lo hagan de forma diligente y prudente o cumpliendo la legalidad.
            </p><p className='paragraph'>
                BEETESTED tiene el derecho de suspender temporalmente y sin preaviso la accesibilidad a LA
                PLATAFORMA, como por ejemplo en el caso de necesidad urgente para el mantenimiento de
                LA PLATAFORMA, actualizaciones de la misma o por razones de seguridad de LA
                PLATAFORMA, así como a reservarse el derecho de prestación o cancelación de los servicios,
                contenidos o de LA PLATAFORMA de forma permanente, intentando ponerlo previamente en
                conocimiento de los Usuarios, siempre que las circunstancias así se lo permitan.
            </p><p className='paragraph'>
                BEETESTED tiene el derecho, pero no la obligación, de oponerse y/o eliminar cualquier
                contenido o actividad que infrinja estas Condiciones de Uso y cualquiera de sus Políticas,
                desde el momento en el que tenga conocimiento efectivo, así como de denegar o cancelar el
                acceso a LA PLATAFORMA a cualquier persona o entidad según lo establecido en estas
                Condiciones de Uso. Por ello, BEETESTED pone a disposición de los Usuarios y de terceros
                un sistema de denuncia unilateral, por el que los mismos notificarán cualquier infracción de las
                presentes Condiciones de Uso y sus Políticas en la siguiente dirección:&nbsp;
                <a href="mailto:hello@beetested.com">hello@beetested.com</a>
            </p>
            <h2 className='title'>
                MISCELÁNEA
            </h2>
            <p className='paragraph'>
                Las presentes Condiciones de Uso constituyen el acuerdo total entre los Usuarios y
                BEETESTED, según corresponda, en lo referente al objeto del presente documento, es decir,
                (i) al uso de LA PLATAFORMA y (ii) al acceso y uso de los Videojuegos disponibles en esta.
                Estas Condiciones de Uso solo se podrán modificar mediante una corrección escrita firmada
                por una persona autorizada de BEETESTED o a través de la publicación de una versión
                revisada de estas.
            </p><p className='paragraph'>
                Si cualquier cláusula de las presentes Condiciones de Uso fuese declarada total o parcialmente
                nula o ineficaz afectará tan solo a esa disposición o parte de la misma, que resulte nula o
                ineficaz, subsistiendo en todo lo demás el resto de las Condiciones de Uso y teniéndose tal
                disposición o la parte de la misma que resulte afectada por no puesta salvo que, por resultar
                esencial a las presentes Condiciones de Uso, hubiese de afectarlas de manera integral.
            </p><p className='paragraph'>
                Los Usuarios podrán dar de baja el Perfil de Usuario de LA PLATAFORMA en cualquier
                momento, sin perjuicio de aquellas obligaciones que hubiesen adquirido y/o que continúen
                vigentes en relación con LA PLATAFROMA y/o el testeo de los Videojuegos en los que
                participen en cada momento, comunicando su decisión a BEETESTED en la siguiente
                dirección: <a href="mailto:hello@beetested.com">hello@beetested.com</a>
            </p><p className='paragraph'>
                La baja del Perfil de Usuario conllevará el cierre del Perfil y no da derecho al Usuario a
                indemnización alguna.
            </p><p className='paragraph'>
                LA PLATAFORMA y sus contenidos se ofrecen en lengua española e inglés, aunque podrán
                estar disponibles en otros idiomas, por lo que, ante cualquier conflicto en la aplicación o
                interpretación de estas Condiciones de Uso, el Aviso Legal, las Políticas de Privacidad y/o la
                Política de Cookies de BEETESTED, siempre prevalecerá la versión en lengua española sobre
                cualquier otra.
            </p><p className='paragraph'>
                Las Condiciones de Uso tendrán vigencia a partir del momento en que el Usuario cree su Perfil
                de Usuario por tiempo indefinido en lo que se refiere al uso de LA PLATAFORMA de
                BEETESTED, hasta que el Usuario diese de baja su Perfil de Usuario o, por cualquier
                circunstancia, LA PLATAFORMA se cerrase.
            </p><p className='paragraph'>
                Ni BEETESTED ni los Usuarios serán responsables o incumplidores de las presentes
                Condiciones de Uso en caso de retraso o incumplimiento por causas de fuerza mayor, en los
                supuestos contemplados en la legislación.
            </p><p className='paragraph'>
                Los Usuarios no podrán ceder total o parcialmente, ni onerosa ni gratuitamente, los derechos u
                obligaciones adquiridos en las presentes Condiciones de Uso. Si se incumple esta prohibición,
                se podrá cancelar el Perfil del Usuario, sin perjuicio del derecho de BEETESTED a ejercitar las
                acciones judiciales, que en su caso procedan por daños y perjuicios que pudieran producirse
                por esa causa.
            </p>
            <h2 className='title'>
                LEY APLICABLE Y JURISDICCIÓN
            </h2>
            <p className='paragraph'>
                Estas Condiciones de Uso, las Políticas de Privacidad y Cookies y el Aviso Legal de LA
                PLATAFORMA, así como cualquier relación entre los Usuarios con BEETESTED, se regirán
                por la legislación española. En caso de conflicto en la aplicación o interpretación de dichos
                documentos, las partes se someten a los Juzgados y Tribunales de Madrid.
            </p><p className='paragraph'>
                Sin perjuicio de lo anterior, cuando el Usuario sea considerado como Consumidor podrá
                reclamar sus derechos como tal en relación con las Condiciones de Uso, el Aviso Legal y las
                Políticas de Privacidad y Cookies de BEETESTED tanto ante los órganos jurisdiccionales en
                España en que esté domiciliado el Consumidor como ante los órganos jurisdiccionales en su
                Estado Miembro de residencia en la Unión Europea. Asimismo, en cumplimiento de lo
                establecido en el artículo 14.1 del Reglamento (UE) 524/2013 del Parlamento Europeo y del
                Consejo de 21 de mayo de 2013, BEETESTED le informa de la existencia de una plataforma
                de resolución extrajudicial de litigios online puesta a disposición por la Comisión Europea,
                disponible en: <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage" target="_blank" rel="noreferrer">
                    https://ec.europa.eu/consumers/odr/
                </a> y a través de la cual los Consumidores podrán someter sus reclamaciones.
            </p>
        </div>
    );
}

export default UseConditions;