import React, { useContext, useEffect, useState } from 'react'
import CloseIconComponent from '../CloseIconComponent/CloseIconComponent'
import { PopupContext } from '../../services/popup-context';
import './GamerProfileSelectedVideoGames.css'

const GamerProfileSelectedVideoGames = (props) => {
    const popUpStatus = useContext(PopupContext);
    const [completedStyle, setCompletedStyle] = useState('')
    const [minToselect, setMinToSelect] = useState(3)
    const [loadingLabel, setLoadingLabel] = useState('0 / '+ minToselect);


    useEffect(()=>  {
        if (popUpStatus.selectedVideoGames){
            
            if (popUpStatus.selectedVideoGames.length >=3){
                props.setDisabled(false);
                setCompletedStyle('completed');
                setMinToSelect(popUpStatus.selectedVideoGames.length)
            }
            else{
                setCompletedStyle('');
                props.setDisabled(true);
            }
            setLoadingLabel(popUpStatus.selectedVideoGames.length + ' / ' + minToselect)
        }

    }, [popUpStatus])

    const doRemoveItemSelected = (toRemoveItem) => {
        const selectedGames = popUpStatus.selectedVideoGames
        const newSelected = selectedGames.filter(function (item) {
            return item.id != toRemoveItem.id
        });
        popUpStatus.setSelectedVideogames(newSelected)
        
        const newResponseGames = popUpStatus.showedList
        newResponseGames.push(toRemoveItem)
        popUpStatus.setShowedList(newResponseGames.sort(function(item1,item2){
            if (item1.title > item2.title) {
                return 1;
              }
              if (item1.title < item2.title) {
                return -1;
              }
              // a must be equal to b
              return 0;
        }))
    }
    
    return (


            <div className='selected-videogames-table'>
                <div className='table-title'>Tus juegos favoritos</div>
                <div className='selected-videogames-list'>
                    {popUpStatus.selectedVideoGames && 
                        popUpStatus.selectedVideoGames.map((item)=>(
                            <div key={item.id} className='selected-game' onClick={(e)=>doRemoveItemSelected(item)}>
                                <div className='item-title'> {item.title} </div>
                                <div className='item-remove'> <CloseIconComponent/> </div>
                            </div>
                        ))
                    }
                </div>
                <div className={completedStyle + ' completed-container'}>
                    {loadingLabel}
                </div>
            </div>

    )
}

export default GamerProfileSelectedVideoGames;
