
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../services/auth.service';
import Login from '../Login/Login';
import Register from './Register';
import './EmailVerified.css'
import { Link, useParams } from 'react-router-dom';

const EmailVerified = () => {
    const {token} = useParams()
    const { verifyEmail } = useContext(AuthContext);
    const [isEmailVerfied, setIsEmailVerified] = useState(false)
    const [hasVerificationError, sethasVerificationError] = useState(false)

    useEffect(() => {
        document.body.classList.add('loading');
        verifyEmail(token)
        .then((response) => {
            setIsEmailVerified(true)
            sethasVerificationError(false)
            document.body.classList.remove('loading');
        })
        .catch((err) => {
            sethasVerificationError(true)
            setIsEmailVerified(false)
            document.body.classList.remove('loading');
        })
    }, [verifyEmail, token])

    return (
        <div className='advertise-container'>
            { isEmailVerfied && 
            <>
                <div className='email-verified-successfully'>
                    <div className='email-verified-successfully-text'>
                        <p className='bold-highlight'>Tu email se ha verificado correctamente.</p>
                        <Link to='/' className='switch-login-link'>
                            ¡Inicia sesión cuando quieras y empieza a testear!
                        </Link>
                    </div>
                </div>

            </>
            }

            { hasVerificationError &&
            <> 
                <div className='email-verified-problem'>
                    <div className='email-verified-problem-text'>
                        <p className='bold-highlight'>Parece que ha habido un problema al verificar tu email.</p>
                        <p>Ha debido de pasar mucho tiempo desde que te registraste y probablemente no verificaste tu email.</p>
                    </div>
                </div>
            </>
            }
            
        </div>
    )
}

export default EmailVerified;