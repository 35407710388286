import DemoViewTextCompleted from '../DemoViewTextCompleted/DemoViewTextCompleted';
import DemoViewTextDescription from '../DemoViewTextDescription/DemoViewTextDescription';
import DemoViewTextInstructions from '../DemoViewTextInstructions/DemoViewTextInstructions';
import OnboardingTestInstructions from '../OnboardingTestInstructions/OnboardingTestInstructions';

const DemoViewText = ({testInfo, startTest, showDescription, showInstructions, isCompleted}) => {
    if (testInfo.has_completed_onboarding_test) {
        return  (
            <div className='text-description-container'>
                <div className='image-container'>
                    <img src={testInfo.image.url} alt={testInfo.image.alt} />
                </div>
                {/* Description */}
                { showDescription && < DemoViewTextDescription
                    testInfo={testInfo}
                    startTest= {startTest}
                />}
                {/* Instructions */}
                {showInstructions && <DemoViewTextInstructions
                    testInfo={testInfo}
                />}
                {/* Completed */}
                {isCompleted && <DemoViewTextCompleted /> }
            </div>
        );
    } else {
        return <OnboardingTestInstructions />;
    }
}

export default DemoViewText;