import "./PopupContentComponents.css"
const HexagonContent = (props) =>{
    return (
        <div className="hexa-container"
            style={{
                visibility: props.show ? "visible" : "hidden",
                display: props.show ? "flex" : "None",
                opacity: props.show ? "1" : "0"
            }}
        >
            {props.childrenButton}
            <div className="outerhexagon outerhexagon1">
                <div className="outerhexagonin1">
                        <div className="outerhexagonin2">
                        </div>
                    </div>
            </div>
                            
            <div className="hexagon hexagon1">
                <div className="hexagonin1">
                    <div className="hexagonin2">
                        {props.children}
                    </div>
                </div>
            </div>
        </div>

    )
}
export default HexagonContent;