import axios from 'axios';
import { API_URL } from '../constants';
const RewardsServices = () => {
    return {
        async getAvailableRewards(token) {
            const response = await axios.get(
                API_URL + '/rewards/show', 
                { headers: token }
            )
            return response;
        },

        async getRewardDetail(token, reward_item_id) {
            const response = await axios.get(
                API_URL + '/rewards/show/' + reward_item_id,
                { headers: token }
            );
            return response;
        },
    };
}

export default RewardsServices;