import { useEffect, useState } from "react";
import './CloseIconComponent.css'

const CloseIconComponent = () => {    
    return (
        <div className="close-icon">
            <div className="line1"/>
            <div className="line2"/>
        </div>
    )
}

export default CloseIconComponent;
