import Profile from '../Profile/Profile';
import DemoList from '../DemoList/DemoList';
import './Home.css';


const Home = () => {

    return (
        <div className='home-container'>
            <Profile />
            <DemoList />
        </div>
    );
}

export default Home;
