import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { API_URL } from "../../constants";
import RewardsServices from "../../services/rewards-services";
import authHeader from "../../services/auth-header";
import'./RewardsItemDetail.css'
import Wallet from "../Wallet/Wallet";

const RewardsItemDetail = ({rewardItem}) => {
    const {
        getRewardDetail
    } = RewardsServices();

    const [rewardItemDetailed, setRewardItemDetailed] = useState(null); 
    const [haveProblems, setHaveProblems] = useState(false);
    const [user, setUser] = useState(null);
    const [problemMsg, setProblemMsg] = useState("");
    const reedemButt = useRef(null);

    useEffect(()=>{
        if(user==null){
            authHeader().then((token) => {
                axios.get(
                    API_URL + '/auth/users/profile',
                    {
                        headers: token
                    }
                ).then((res) => {
                    setUser(res.data);

                    getRewardDetail(token, rewardItem.id).then((response) =>{

                        setRewardItemDetailed(
                            {
                                ...rewardItem,
                                ...response.data
                            }
                        )
                    });
                });
            });
        }
        else if(reedemButt.current && user && rewardItemDetailed){
            checkRedeem();
        }
    },[rewardItemDetailed]);

    const checkRedeem= ()=>{
        if(user.wallet.xp < rewardItemDetailed.minimum_xp){
            setHaveProblems(true);
            reedemButt.current.disabled = true;
            setProblemMsg(
                "Vaya, parece que no puedes permitirte este objeto porque te falta experiencia. " + 
                "Sin embargo, puedes realizar más tests para conseguirla."
            );
        }
        else if(user.wallet.honeycoins < rewardItemDetailed.price){
            setHaveProblems(true);
            reedemButt.current.disabled = true;
            setProblemMsg(
                "Vaya, parece que no puedes permitirte este objeto porque te faltan monedas. " +
                "Sin embargo, puedes realizar más tests para conseguirlas."
            );

        }
        else if( rewardItemDetailed.units < 1){
            setHaveProblems(true);
            reedemButt.current.disabled = true;
            setProblemMsg(
                "Lo sentimos, pero no nos quedan más unidades." +
                "  Te avisaremos cuando repongamos, ¡mantente alerta!"
            );
        }
        else{
            setHaveProblems(false);
            reedemButt.current.disabled = false;
        }
        
    };

    return (
        <div className="main-reward-item-container">
        { rewardItemDetailed &&
            <div className="reward-item-detail"> 
                <div className="purchase-description">
                    <img className="detail-image"
                        src={rewardItemDetailed.image.url} 
                        alt={rewardItemDetailed.image_alt}
                    />
                    <p>
                        {rewardItemDetailed.description}
                    </p>
                </div>
                <div className='redeem-reward-container'>
     
                    <div className="reward-purchase-info">
                        <div className="rewards-wallet-container">

                            <p className="rewards-wallet-title">Tu Cartera</p>
                            <hr className="rounded-divider"></hr>
                            <div className="rewards-wallet">
                                <Wallet 
                                    walletValuesClassName={'rewards-wallet-values'}
                                    walletLabelsClassName={'rewards-wallet-labels'}
                                    wallet={user.wallet}
                                />
                            </div>
                        </div>
                        {haveProblems && <div className="problems-container">
                            <p className="redeem-problem">{problemMsg}</p>
                        </div>}
                        <button ref={reedemButt} className="redeem-butt">Comprar</button>
                        <div className="purchase-requirements">
                            <p>HoneyCoins: {rewardItemDetailed.price}</p>
                            <p>Experiencia requerida: {rewardItemDetailed.minimum_xp}</p>    
                        </div>
                    </div>
                </div>
            </div>
        }
    </div>
    );
}

export default RewardsItemDetail;