import { Link } from 'react-router-dom';
import { AuthContext } from '../../services/auth.service';
import { useContext, useEffect, useState } from 'react';
import './MenuItems.css'
import CollapsableIconComponent from '../CollapsibleIconComponent/CollapsibleIconComponent';
import { PopupContext } from '../../services/popup-context';

const MenuItems = (props) => { 

    const popUpStatus = useContext(PopupContext);

    
    const { isAuthed, logout } = useContext(AuthContext);
    const [collapsibleList, setCollapsibleList] = useState([])
    const [matches, setMatches] = useState(
        window.matchMedia("(max-width: 768px)").matches
      )

    const faqLink =  <a
        className='item'
        href='https://beetested.com/faq'
        target='_blank'
        rel='noreferrer'
    >
        FAQ
    </a>

    const logutLink = <>{ isAuthed && <Link
        className={'item' + (isAuthed ? '' : ' hidden')}
        onClick={logout}
        to='/'
    >
        logout
    </Link>}</>

    const homeLink = <Link className='main-item' to='/'>
    Home
    </Link>;

    const rewardsLink = <Link className='main-item' to='/rewards'>
    Rewards
    </Link>;
    
    useEffect(()=>{
        window
        .matchMedia("(max-width: 768px)")
        .addEventListener('change',e => setMatches( e.matches ));

        if(matches){
            addAllToCollapsible();
        }
        else{
            addSomeToCollapsible();
        }
    

    }, [matches, isAuthed, popUpStatus]);

    const addSomeToCollapsible = ()=> {
        setCollapsibleList([faqLink, logutLink]);

    }
    const addAllToCollapsible = ()=> {
        setCollapsibleList([homeLink, rewardsLink, popUpStatus.newsLink , faqLink, logutLink]);

    }

    return (
        <>
            <div className='menu-items'>
                <ul className='menu-item-list'>
                    {homeLink}
                    {rewardsLink}
                    {popUpStatus.newsLink}
                </ul>
            </div>
            <div className='collapsable-list'>
                    <CollapsableIconComponent collapsibleList={collapsibleList}/>
            </div>
        </>
    )
 }

 export default MenuItems;