import CookiesService from "./cookies.service";
import {
    JWT_ACCESS_COOKIE_NAME,
    JWT_REFRESH_COOKIE_NAME,
    API_URL
 } from "../constants";
import axios from "axios";

export default async function authHeader() {
  const { getCookie, setCookie } = CookiesService();
  const jwt_access_token = getCookie(JWT_ACCESS_COOKIE_NAME);
  let ret = {};

  if (jwt_access_token) {
    ret = { Authorization: 'Bearer ' + jwt_access_token };
  } else {
    const refresh = getCookie(JWT_REFRESH_COOKIE_NAME);
    if (refresh) {
      const res = await axios.post(
        API_URL + '/auth/token/refresh',
        { refresh }
      );
      const access = res.data.access;
      setCookie(JWT_ACCESS_COOKIE_NAME, access, 0.01);
      setCookie(JWT_REFRESH_COOKIE_NAME, refresh, 7);
      ret = { Authorization: 'Bearer ' + access};
    }
  }

  return ret;
}