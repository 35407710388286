const DemoViewTextCompleted = () =>{
    return (
        <div className='test-completed'>
            <p>Ya has completado este test.</p>
            <p>¡Gracias por participar!</p>
            <p>Verás la recompensa en tu perfil en cuanto comprobemos que todo es correcto :)</p>
        </div>
    )
}

export default DemoViewTextCompleted;