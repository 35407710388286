import './DemoList.css';
import DemoCard from '../DemoCard/DemoCard';
import { API_URL } from '../../constants';
import { useEffect, useState } from 'react';
import authHeader from '../../services/auth-header';
import axios from 'axios';


const DemoList = (props) => {
    props = {
        ...props
    }
    const [demos, setDemos] = useState([]);

    useEffect (() => {
        document.body.classList.add('loading');
        authHeader().then(token => {
            axios.get(
                API_URL + '/tests/show',
                { headers: token }
            ).then(res => {
                setDemos(res.data);
                document.body.classList.remove('loading');
            });
        });
    }, [])

    return (
        <div className='demo-list-container'>
            <div className='title'>
                <h1>Demos disponibles</h1>
            </div>
            <div className='demo-cards-container'>
                {demos.map((demo) => (
                    <DemoCard
                        key={demo.id}
                        href={'/demo/' + demo.slug}
                        img={demo.image ? demo.image.url : '/img/base-demo-image.png'}
                        title={demo.name ? demo.name : demo.videogame.title}
                    />
                ))}
            </div>
        </div>
    );
}

export default DemoList
